import { AiOutlineHome, AiOutlineSetting } from "react-icons/ai";
import { ImList2 } from "react-icons/im";
import { BsWallet2 } from "react-icons/bs";
import { GrMailOption } from "react-icons/gr";
import { PiTimer } from "react-icons/pi";
import { MdOutlineEditCalendar, MdGroup, MdPayment } from "react-icons/md";
import { PiOfficeChairLight } from "react-icons/pi";
import { LuLogOut } from "react-icons/lu";

export const regularDashboard = [
  {
    id: 1,
    name: "Dashboard",
    to: "/dashboard",
    icon: <AiOutlineHome />,
  },
  {
    id: 2,
    name: "Shortlisted",
    to: "/shortlist",
    icon: <ImList2 />,
  },
  {
    id: 3,
    name: "Job Activity",
    to: "/activity",
    icon: <PiTimer />,
  },
  {
    id: 4,
    name: "Payment",
    to: "/payment",
    icon: <BsWallet2 />,
  },
  {
    id: 5,
    name: "Messages",
    to: "/messages",
    icon: <GrMailOption />,
  },
  {
    id: 6,
    name: "Locum Manager",
    to: "/locummanager",
    icon: <MdOutlineEditCalendar />,
  },
  {
    id: 7,
    name: "Settings",
    to: "/settings",
    icon: <AiOutlineSetting />,
  },
  {
    id: 8,
    name: "Logout",
    to: "/",
    icon: <LuLogOut />,
  },
];
