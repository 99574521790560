import React, { useState } from "react";
import "./Settings.css";
import ProfilePage from "../../account/profile/ProfilePage";
import PasswordPage from "../../account/password/PasswordPage";
import NotificationPage from "../../account/notification/NotificationPage";

const Settings = () => {
  const [toggle, setToggle] = useState(0);

  const updateToggle = (id) => {
    setToggle(id);
  };

  return (
    <div className="settings-container">
      <div className="settings-right">
        <h3>Account Settings</h3>
        <div className="settings-right-bottom">
          <div className="settings-options">
            <span
              onClick={() => {
                updateToggle(0);
              }}
              className={toggle === 0 ? "settings-active" : ""}
            >
              My profile
            </span>
            <span
              onClick={() => {
                updateToggle(1);
              }}
              className={toggle === 1 ? "settings-active" : ""}
            >
              Password
            </span>
            <span
              onClick={() => {
                updateToggle(2);
              }}
              className={toggle === 2 ? "settings-active" : ""}
            >
              Notifications
            </span>
          </div>

          <div className="settings-tab-result">
            {toggle === 0 ? (
              <div>
                <ProfilePage />
              </div>
            ) : toggle === 1 ? (
              <div>
                <PasswordPage />
              </div>
            ) : toggle === 2 ? (
              <div>
                <NotificationPage />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
