import React from "react";
import "./ConversationBroad.css";
import pics from "../../../../../assets/message-pics.png";

const ConversationBroad = ({type, handleShowChat}) => {
  return (
    <div className="conversation-broad">
      <h6>{type}</h6>

      <div className="chats-list">
        <div className="single-chat-contact background-apply" onClick={handleShowChat}>
          <img src={pics} alt="pics" />
          <div className="text-content">
            <h5>Eleanor Pena</h5>
            <p>
              Hey man, i wanna find out about your listings and stuff... have
              you got a 3-bed?
            </p>

            <div className="date-content">
                <p>Today, 3:00PM</p>
            </div>
          </div>
        </div>

        <div className="single-chat-contact" onClick={handleShowChat}>
          <img src={pics} alt="pics" />
          <div className="text-content">
            <h5>Eleanor Pena</h5>
            <p>
              Hey man, i wanna find out about your listings and stuff... have
              you got a 3-bed?
            </p>

            <div className="date-content">
                <p>Today, 3:00PM</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConversationBroad;
