import React, { memo } from 'react';
import './PostJobBtn.css'

const PostJobBtn = ({label, customStyle}) => {
  return (
    <button style={customStyle} className='postjobBtn'>
        {label}
    </button>
  )
}

export default memo(PostJobBtn)