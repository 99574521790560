import { useEffect, useState } from "react";
import { errorProps, getLocalStorageWithExpiration } from "../utils";
import { toast } from "react-toastify";
import { getSoD } from "../endpoints/user";

const useOrganisationRole = () => {
  const key = "user-values";
  const [loading, setLoading] = useState(false);
  const [orgList, setOrgList] = useState([]);

  const [keyValues] = useState(getLocalStorageWithExpiration(key));
  const allow = keyValues?.acc_type === "organisation";

  const fetchOrgList = async (values) => {
    const { token, acc_type } = values;
    const type = "organisation-role";
    setLoading(true);
    try {
      const { data, status } = await getSoD(token, acc_type, type);
      if (status === 201 || status === 200 || data.status === true) {
        const newOrgLists = data?.data?.allOrganisationRole?.map((i) => {
          return { label: i.name, value: i.id };
        });
        setOrgList(newOrgLists);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setOrgList([]);
      if (error.message === "Network Error") {
        toast.error(error.message, errorProps);
      } else {
        toast.error("Can not get organisation roles", errorProps);
      }
    }
  };

  useEffect(() => {
    if (allow) fetchOrgList(keyValues);
  }, []);

  return { loading, orgList };
};

export default useOrganisationRole;
