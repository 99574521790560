import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const CustomMenu = ({ anchorEl, handleClose, options, handleNav }) => {
  const handle = () => {
    handleClose()
    handleNav && handleNav()
  }
  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      {options?.map((option, index) => (
        <MenuItem key={index} onClick={handle}>{option}</MenuItem>
      ))}
    </Menu>
  );
};

export default CustomMenu;
