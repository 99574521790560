import React, { useState } from "react";
import "./FindJobs.css";
import PhysiciansItem from "../physiciansItem/PhysiciansItem";
import PhamarcistsItem from "../phamarcistItem/PhamarcistItem";
import AllJobsItem from "../allJobsItem/AllJobsItem";
import NursesItems from "../nursesItems/NursesItems";

const FindJobs = () => {
  const [toggle, setToggle] = useState(0);

  const updateToggle = (id) => {
    setToggle(id);
  };

  return (
    <div className="findJobs-container" data-aos="zoom-in">
      <h3>
        Search through Healthcare professional <br /> Divisions for Available
        Locum Jobs near you.
      </h3>

      {/* tabs */}
      <div className="findJobs-tabs">
        <span
          className={toggle === 0 ? "jobs-tabs-1" : "jobs-tabs"}
          onClick={() => {
            updateToggle(0);
          }}
        >
          All Jobs
        </span>
        <span
          className={toggle === 1 ? "jobs-tabs-1" : "jobs-tabs"}
          onClick={() => {
            updateToggle(1);
          }}
        >
          Pharmacists
        </span>
        <span
          className={toggle === 2 ? "jobs-tabs-1" : "jobs-tabs"}
          onClick={() => {
            updateToggle(2);
          }}
        >
          Physicians
        </span>
        <span
          className={toggle === 3 ? "jobs-tabs-1" : "jobs-tabs"}
          onClick={() => {
            updateToggle(3);
          }}
        >
          Nurses
        </span>
        <span
          className={toggle === 4 ? "jobs-tabs-1" : "jobs-tabs"}
          onClick={() => {
            updateToggle(4);
          }}
        >
          Lab. Scientists
        </span>
      </div>
      {/* tabs */}

      {/* job result */}
      {toggle === 0 ? (
        <div>
          <AllJobsItem />
        </div>
      ) : toggle === 1 ? (
        <div>
          <PhamarcistsItem />
        </div>
      ) : toggle === 2 ? (
        <div>
          <PhysiciansItem />
        </div>
      ) : toggle === 3 ? (
        <div>
          <NursesItems />
        </div>
      ) : toggle === 4 ? (
        <div>
          <NursesItems />
        </div>
      ) : null}
      {/* job result */}
    </div>
  );
};

export default FindJobs;
