import React from "react";
import "./JobItem.css";
import { CiLocationOn } from "react-icons/ci";

const JobItem = ({
  title,
  rate,
  jobLoc,
  jobDesc,
  jobImg,
  jobOrg,
  jobOrgloc,
}) => {
  return (
    <>
      <div className="job-result-box">
        <div className="job-box-top">
          <h5>{title}</h5>
          <span className="job-rate">{rate}/hr</span>
        </div>
        <div className="job-location">
          <CiLocationOn className="job-location-icon" />
          <span className="job-location-text">{jobLoc}</span>
        </div>
        <p className="job-description">{jobDesc}</p>
        <div className="job-result-bottom">
          <div className="job-result-image">
            <img src={jobImg} alt="logo" />
          </div>
          <div className="job-result-others">
            <h6>{jobOrg}</h6>
            <div className="job-location">
              <CiLocationOn className="job-location-icon" />
              <span className="job-location-text">{jobOrgloc}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobItem;
