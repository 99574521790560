import React, { useState } from "react";
import "./DashboardNavbarMobile.css";
import { BiMenu } from "react-icons/bi";
import Logo from "../../assets/Group.png";
import DashboardDrawer from "../dashboardDrawer/DashboardDrawer";
import { Link } from "react-router-dom";
import { Slide } from "react-reveal";

const DashboardNavbarMobile = ({ navList }) => {
  const [showNavDrawer, setShowNavDrawer] = useState(false);
  return (
    <div className={"mobileNavWrapper " + (showNavDrawer ? 'height-bg-showNavDrawer' : '')}>
      <div className="navbar-box">
        <div className="dashboardNavbarMobile-container">
          <Link to="/">
            <div className="logo-img">
              <img src={Logo} alt="medic" />
            </div>
          </Link>

          <div className="menu">
            <div className="right-nav">
              {!showNavDrawer && (
                <div onClick={() => setShowNavDrawer(true)}>
                  <BiMenu className="bag" />
                </div>
              )}
              {showNavDrawer && (
                <div className="cancel" onClick={() => setShowNavDrawer(false)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M1 13L13 0.999969"
                      stroke="#245293"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M13 13L1 0.999969"
                      stroke="#245293"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              )}
            </div>
          </div>
        </div>

        <Slide>
          <div className="nav-nav-box">
            {showNavDrawer && <DashboardDrawer navList={navList} />}
          </div>
        </Slide>
      </div>
    </div>
  );
};

export default DashboardNavbarMobile;
