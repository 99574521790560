import React from "react";
import "./Dashboard.css";
import { BiBriefcase } from "react-icons/bi";
import { BsBriefcaseFill, BsArrowRight } from "react-icons/bs";
import { FaUserAlt } from "react-icons/fa";
import { jobsArray2 } from "../../components/findJobs/Jobs";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { applicationsArray } from "../../components/findJobs/Jobs";
import BoxAnalysis from "./dashboardComponents/BoxAnalysis";

const Dashboard = () => {
  return (
    <div className="dashboard-container">
      <div className="dashboard-right">

        {/* dashboard main content */}
        <div className="dashboard-right-bottom">
          {/* info box */}
          <div className="info-box">
            <BoxAnalysis
              num={"24"}
              title="Application Sent"
              bg={"#90ECDE33"}
              icon={
                <BiBriefcase style={{ color: "#00B297", fontSize: "25px" }} />
              }
            />
            <BoxAnalysis
              num={"04"}
              title="Scheduled Interviews"
              bg={"#FFF2E5"}
              icon={
                <BsBriefcaseFill
                  style={{ color: "#F5A656", fontSize: "25px" }}
                />
              }
            />
            <BoxAnalysis
              num={"2K"}
              title="Profile Views"
              bg={"#FFE5E8"}
              icon={
                <div
                  style={{
                    padding: "8px",
                    backgroundColor: "#D6FCFF",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <FaUserAlt style={{ color: "#5ECFD6", fontSize: "18px" }} />
                </div>
              }
            />
          </div>
          {/* info box */}

          {/* explore more jobs */}
          <div className="exploreMore-container">
            <div className="exploreMore-top">
              <h3>
                Seach for available <br /> locum jobs
              </h3>
              <div className="home-box-one-button">
                <button>
                  Enter Division <BsArrowRight />
                </button>
              </div>
            </div>

            <div className="exploreMore-bottom">
              <h4>Job Alerts</h4>
              <div className="exploreMore-jobs">
                {jobsArray2.map((job) => {
                  return (
                    <div className="exploreMore-Jobs-box">
                      <div className="exploreMore-title-box">
                        <div className="title">
                          <h4>{job.jobTitle}</h4>
                          <span>Rate:N{job.rate}/hr</span>
                        </div>
                        <div className="image">
                          <img src={job.oranizationIMG} alt="img" />
                        </div>
                      </div>
                      <div className="desc">
                        <p>{job.des}</p>
                      </div>
                      <div className="applicants">
                        <h5>15 applicants</h5>
                        <BsArrowRight />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {/* explore more jobs */}

          {/* applications info*/}
          <div className="applications-info-container">
            <h4>My Applications</h4>

            <div className="desktop-view">
              {/* head */}
              <div className="applications-info-heading">
                <span className="head head-1">Company Name</span>
                <span style={{ width: "16%" }} className="head">
                  Status
                </span>
                <span style={{ width: "16%" }} className="head">
                  Date Applied
                </span>
                <span style={{ width: "13%" }} className="head">
                  Time
                </span>
              </div>
              {/* head */}

              {/* content */}
              {applicationsArray.map((app) => {
                return (
                  <div>
                    <div className="applications-info-content">
                      <div className="applications-info-content-box">
                        <span className="content-1">
                          <span className="img">
                            <img src={app.hospitalImg} alt="hos-img" />
                          </span>
                          <span className="title">
                            <h6>{app.hospitalName}</h6>
                            <span className="icon-loc-box">
                              <HiOutlineLocationMarker className="icon-loc" />{" "}
                              {app.hospitalLocation}
                            </span>
                          </span>
                        </span>
                        <div style={{ width: "16%" }}>
                          <span className="status">{app.status}</span>
                        </div>
                        <span style={{ width: "16%" }} className="date">
                          {app.date}
                        </span>
                        <span style={{ width: "13%" }} className="date">
                          {app.time}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
              {/* content */}
            </div>

            <div className="mobile-application-jobs">
              <div className="all-jobs-app">
                {applicationsArray?.map((app) => (
                  <div className="job-single">
                    <div className="flex">
                      <span className="label">Company Name</span>
                      <div
                        style={{
                          width: "50%",
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                        }}
                      >
                        <span className="content-1">
                          <span className="img">
                            <img src={app.hospitalImg} alt="hos-img" />
                          </span>
                          <span className="title">
                            <h6>{app.hospitalName}</h6>
                            <span className="icon-loc-box">
                              <HiOutlineLocationMarker className="icon-loc" />{" "}
                              {app.hospitalLocation}
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>

                    <div className="flex">
                      <span className="label">Status</span>
                      <div
                        style={{
                          width: "50%",
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                        }}
                      >
                        <span className="status">{app.status}</span>
                      </div>
                    </div>

                    <div className="flex">
                      <span className="label">Date Applied</span>
                      <div
                        style={{
                          width: "50%",
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                        }}
                      >
                        <span className="date">{app.date}</span>
                      </div>
                    </div>

                    <div className="flex">
                      <span className="label">Time</span>
                      <div
                        style={{
                          width: "50%",
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                        }}
                      >
                        <span className="date">{app.time}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* applications info */}
          {/* applications info */}
        </div>
        {/* dashboard main content */}
        {/* dashboard main content */}
      </div>
    </div>
  );
};

export default Dashboard;
