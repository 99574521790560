import { useEffect, useState } from "react";
import { errorProps, getLocalStorageWithExpiration } from "../utils";
import { toast } from "react-toastify";
import { getSoD } from "../endpoints/user";

const useDivisionRole = () => {
  const key = "user-values";
  const [loading, setLoading] = useState(false);
  const [divisonList, setDivisonList] = useState([]);

  const [keyValues] = useState(getLocalStorageWithExpiration(key));
  const allow = keyValues?.acc_type === "individual";

  const fetchDivison = async (values) => {
    const { token, acc_type } = values;
    const type = "divisions";
    setLoading(true);
    try {
      const { data, status } = await getSoD(token, acc_type, type);
      if (status === 201 || status === 200 || data.status === true) {
        const newDivisionLists = data?.data?.allDivision?.map((i) => {
          return { label: i.name, value: i.id };
        });
        setDivisonList(newDivisionLists);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setDivisonList([]);
      if (error.message === "Network Error") {
        toast.error(error.message, errorProps);
      } else {
        toast.error("Can not get division roles", errorProps);
      }
    }
  };

  useEffect(() => {
    if (allow) fetchDivison(keyValues);
  }, []);

  return { loading, divisonList };
};

export default useDivisionRole;
