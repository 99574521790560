import React, { useState } from "react";
import "./Explore.css";
import squareRed from "../../assets/red-square.png";
import squareOrange from "../../assets/orange-square.png";
import squareGreen from "../../assets/green-square.png";

const Explore = () => {
  const [toggle, setToggle] = useState(1);

  const updateToggle = (id) => {
    setToggle(id);
  };

  return (
    <div className="explore-container">
      <h3 data-aos="fade-right">How It Works</h3>
      <div className="explore-tabs">
        <span
          className={toggle === 1 ? "explore-tabs-active" : "explore-tabs"}
          onClick={() => {
            updateToggle(1);
          }}
        >
          For Healthcare Professionals
        </span>
        <span
          className={toggle === 2 ? "explore-tabs-active" : "explore-tabs"}
          onClick={() => {
            updateToggle(2);
          }}
        >
          For Healthcare Providers
        </span>
      </div>
      <p className={toggle === 1 ? "explore-text-show" : "explore-text"}>
        Uncover limitless, rewarding and flexible healthcare practice; apply and
        earn hourly from regularly updated locum vacancies as well as post a
        vacancy when in need of a placeholder.
      </p>
      <p className={toggle === 2 ? "explore-text-show" : "explore-text"}>
        Our locum staffing solution helps you focus on quality healthcare
        service delivery by providing you with qualified and skilled healthcare
        professionals for your temporary staffing needs.
      </p>

      <div className="explore-boxes-container" data-aos="fade-up-right">
        <div className="explore-box">
          <div className="explore-box-icon">
            <img src={squareGreen} alt="" />
          </div>
          <h5 className="square-green">Search locum Vacancies</h5>
          <p>Search for locum vacancies near you</p>
        </div>
        <div className="explore-box">
          <div className="explore-box-icon">
            <img src={squareRed} alt="" />
          </div>
          <h5 className="square-red">Apply</h5>
          <p>Apply seamlessly with just few clicks</p>
        </div>
        <div className="explore-box">
          <div className="explore-box-icon">
            <img src={squareOrange} alt="" />
          </div>
          <h5 className="square-orange">Get hired</h5>
          <p>Get hired and earn instantly on job completion</p>
        </div>
      </div>
    </div>
  );
};

export default Explore;
