import { config } from "../config";
import axios from "axios";

const signupHeader = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

export const signUp = (fields) =>
  axios
    .post(`${config.testApiBaseUrl}/register`, fields, signupHeader)
    .then((res) => {
      return { data: res.data, status: res.status };
    });

export const signIn = (fields) =>
  axios.post(`${config.testApiBaseUrl}/login`, fields).then((res) => {
    return { data: res.data, status: res.status };
  });

export const forgetPassword = (fields) =>
  axios.post(`${config.testApiBaseUrl}/forgot-password`, fields).then((res) => {
    return { data: res.data, status: res.status };
  });