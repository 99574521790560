import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import "./ProfileEdit.css";
import { GoArrowRight } from "react-icons/go";
import { CgProfile } from "react-icons/cg";
import ProfileEditInput from "./components/profileEditInput/ProfileEditInput";
import CustomPhoneInput from "./components/customPhoneInput/CustomPhoneInput";
import ProfileEditDatenCv from "./components/profileEditDatenCv/ProfileEditDatenCv";
import CustomAutoComplete from "./components/customAutoComplete/CustomAutoComplete";
import useCountries from "../../../hook/useCountries";
import toLower from "lodash/fp/toLower";
import CalendarIcon from "../../../components/icon/CalendarIcon";
import FileIcon from "../../../components/icon/FileIcon";
import useSortAlphabetically from "../../../hook/useSortAlphabetically";
import useGenerateProvincesList from "../../../hook/useGenerateProvincesList";
import { useDropzone } from "react-dropzone";
import { useParams } from "react-router-dom";
import useUpdateUser from "../../../hook/useUpdateUser";
import { useTypedSelector } from "../../../hook/useTypedSelector";
import useSkills from "../../../hook/useSkills";
import { isEmpty } from "../../../utils";
import { config } from "../../../config";
import useDivisionRole from "../../../hook/useDivisionRole";
import useOrganisationRole from "../../../hook/useOrganisationRole";

const ProfileEdit = () => {
  const user = useTypedSelector((store) => store.user);
  const items = ["Email", "Text Message", "WhatsApp", "Social Media"];
  const [profilePics, setProfilePics] = useState(null);
  const [profileCv, setProfileCv] = useState(null);
  const [license, setLicense] = useState(null);
  const { skillsList } = useSkills();
  const { divisonList } = useDivisionRole();
  const { orgList } = useOrganisationRole();
  const [skill, setSkill] = useState();
  const countries = useCountries();

  const {
    values,
    handleChange,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useUpdateUser();

  //console.log("values", values.organisation_role, values.division);
  const { type } = useParams();

  const findSingleDivision = useMemo(() => {
    return divisonList.find((i) => i.label === user?.division?.name);
  }, [divisonList, user?.division?.name]);

  const findSingleRole = useMemo(() => {
    return orgList.find((i) => i.label === user?.organisation_role?.name);
  }, [orgList, user?.organisation_role]);

  useEffect(() => {
    setFieldValue("organisation_role", findSingleRole);
    setFieldValue("division", findSingleDivision);
  }, [findSingleRole, findSingleDivision]);

  const handleAddSkill = () => {
    if (!isEmpty(skill)) {
      if (!values.skills.includes(skill.label)) {
        const newSkills = [...values.skills, skill.label];
        setFieldValue("skills", newSkills);
        setSkill();
      } else {
        setSkill();
      }
    }
  };

  const handleChangeSelectDnR = (option) => {
    handleChange({
      target: {
        name: "organisation_role",
        value: option,
      },
    });
    handleChange({
      target: {
        name: "division",
        value: option,
      },
    });
  };

  const handleDeleteSkill = (id) => {
    const updatedSkillList = values.skills?.filter((i, index) => index !== id);
    setFieldValue("skills", updatedSkillList);
  };

  const sortAlphabetically = useSortAlphabetically();
  const generateProvincesList = useGenerateProvincesList();

  const countrySelectedOption = useMemo(() => {
    return countries.find((i) => toLower(i.name) === toLower(values.country));
  }, [countries, values.country]);

  const changeCountryHandler = useCallback((option) => {
    handleChange({
      target: {
        name: "country",
        value: option?.name,
      },
    });
    handleChange({
      target: {
        name: "state",
        value: "",
      },
    });
  }, []);

  const provinceOptions = useMemo(() => {
    return sortAlphabetically({
      items: generateProvincesList(values.country),
      byProperty: "name",
    }).map((i) => {
      return {
        value: i.code,
        label: i.name,
      };
    });
  }, [generateProvincesList, sortAlphabetically, countrySelectedOption]);

  const onChangeSelect = useCallback((option) => {
    handleChange({
      target: {
        name: "state",
        value: option?.label,
      },
    });
  }, []);

  const selectedProvinceOption = useMemo(() => {
    return provinceOptions.find(
      (i) => toLower(i.label) === toLower(values.state)
    );
  }, [provinceOptions, values.state]);

  const handleUploadProfilePics = useCallback((files) => {
    setProfilePics(
      ...files.map((file) => {
        handleChange({
          target: {
            name: "profile_pix",
            value: Object.assign(file, { preview: URL.createObjectURL(file) }),
          },
        });
        return Object.assign(file, { preview: URL.createObjectURL(file) });
      })
    );
  }, []);

  const handleUploadProfileCv = useCallback((files) => {
    setProfileCv(
      ...files.map((file) => {
        handleChange({
          target: {
            name: "cv",
            value: Object.assign(file, { preview: URL.createObjectURL(file) }),
          },
        });
        return Object.assign(file, { preview: URL.createObjectURL(file) });
      })
    );
  }, []);

  const handleUploadLicense = useCallback((files) => {
    setLicense(
      ...files.map((file) => {
        handleChange({
          target: {
            name: "license",
            value: Object.assign(file, { preview: URL.createObjectURL(file) }),
          },
        });
        Object.assign(file, { preview: URL.createObjectURL(file) });
      })
    );
  }, []);

  const {
    getRootProps: getRootProfilePicsProps,
    getInputProps: getInputProfilePicsProps,
  } = useDropzone({ onDrop: handleUploadProfilePics });

  const {
    getRootProps: getRootProfileCvProps,
    getInputProps: getInputProfileCvProps,
  } = useDropzone({ onDrop: handleUploadProfileCv });

  const {
    getRootProps: getRootLicenseProps,
    getInputProps: getInputLicenseProps,
  } = useDropzone({ onDrop: handleUploadLicense });

  const handleDateChange = (event) => {
    setFieldValue("date_of_birth", event.target.value);
  };

  const displayProfilePics = user?.profile_pix || profilePics?.preview;
  const sourceImg =
    profilePics?.preview || `${config.getPublicImages}/${user?.profile_pix}`;

  return (
    <div className="profile-edit-container">
      <div className="heading">
        <h6>Profile</h6>
      </div>

      <div className="profile-edit-content">
        <div className="part">
          <h2>1. Your Profile</h2>

          <div className="input-group">
            <div className="upload-img-bg">
              {displayProfilePics && <img src={sourceImg} alt="profile-pics" />}

              <div>
                <input type="files" {...getInputProfilePicsProps()} />
                <button {...getRootProfilePicsProps()}>
                  <span>Upload Your Photo</span>
                  <CgProfile />
                </button>
              </div>
            </div>
            <ProfileEditInput
              id="name"
              label="Full Name"
              placeholder="Please enter your full name"
              type="text"
              value={values.name}
              onChange={handleChange("name")}
              touched={touched.name}
              error={errors.name}
              onBlur={(e) => handleBlur(e)}
            />
            <ProfileEditInput
              id="email"
              label="Email address"
              placeholder="Enter your email address"
              type="text"
              value={values.email}
            />

            <div className="auto-complete-con">
              <CustomPhoneInput
                id="phone"
                label="Phone Number"
                type="number"
                value={values.phone}
                onChange={handleChange("phone")}
                touched={touched.phone}
                error={errors.phone}
                onBlur={(e) => handleBlur(e)}
              />
            </div>

            <div className="auto-complete-con">
              <CustomAutoComplete
                id="country"
                isClearable={false}
                label="Nationality"
                placeholder={"select country"}
                options={countries}
                initOption={countrySelectedOption}
                onChange={changeCountryHandler}
              />
            </div>

            <div className="auto-complete-con">
              <CustomAutoComplete
                id="state"
                isClearable={false}
                label="Provinces"
                placeholder={"select provinces"}
                options={provinceOptions}
                initOption={selectedProvinceOption}
                onChange={onChangeSelect}
              />
            </div>

            <ProfileEditInput
              id="city"
              label="City"
              placeholder="Please enter your city"
              type="text"
              value={values.city}
              onChange={handleChange("city")}
            />
            <ProfileEditInput
              id="address"
              label="Address"
              placeholder="Enter your address"
              type="text"
              value={values.address}
              onChange={handleChange("address")}
            />

            <ProfileEditInput
              id="postal_code"
              label="Postal Code"
              placeholder="Please enter your postal code"
              type="number"
              value={values.postal_code}
              onChange={handleChange("postal_code")}
            />

            <div className="auto-complete-con">
              <CustomAutoComplete
                isClearable={false}
                label="Account Type"
                placeholder={"Select account type"}
                initOption={
                  type === "individual"
                    ? {
                        label: "Individual",
                        value: "Individual",
                      }
                    : {
                        label: "Organisaction",
                        value: "Organisaction",
                      }
                }
              />
            </div>

            <ProfileEditDatenCv
              id="date_of_birth"
              label="Date of birth"
              type="date"
              value={
                values.date_of_birth
              }
              onChange={handleDateChange}
              icon={<CalendarIcon />}
            />

            <div {...getRootProfileCvProps()}>
              <ProfileEditDatenCv
                label="Upload CV"
                value={profileCv?.name || user?.cv}
                placeholder="Upload CV"
                icon={<FileIcon />}
                {...getInputProfileCvProps()}
              />
            </div>

            <ProfileEditInput
              id="about_us"
              label="How did you hear about us?"
              type="text"
              value={values.about_us}
              onChange={handleChange("about_us")}
            />
          </div>
        </div>

        <hr />

        <div className="part">
          <h2>2. {type === "individual" ? "Your Career" : "Your Operation"}</h2>

          <div className="input-group">
            {type === "individual" && (
              <div className="auto-complete-con">
                <CustomAutoComplete
                  id="division"
                  isClearable={false}
                  placeholder={"Select division"}
                  initOption={values.division}
                  options={divisonList}
                  customStyles={{
                    control: {
                      width: "100%",
                      borderRadius: "100px",
                    },
                  }}
                  onChange={(e) => {
                    handleChangeSelectDnR(e);
                  }}
                />
              </div>
            )}
            {type === "individual" ? (
              <div className="inner-career-inputs">
                <ProfileEditInput
                  id="career_state"
                  label="Career state"
                  placeholder="Please enter career state"
                  type="text"
                  value={values.career_state}
                  onChange={handleChange("career_state")}
                />
                <ProfileEditInput
                  id="career_city"
                  label="Career city"
                  placeholder="Please enter career city"
                  type="text"
                  value={values.career_city}
                  onChange={handleChange("career_city")}
                />
              </div>
            ) : (
              <div className="inner-career-inputs">
                <ProfileEditInput
                  id="operation_state"
                  label="Operation state"
                  placeholder="Please enter operation state"
                  type="text"
                  value={values.operation_state}
                  onChange={handleChange("operation_state")}
                />
                <ProfileEditInput
                  id="operation_city"
                  label="Operation city"
                  placeholder="Please enter opeartion city"
                  type="text"
                  value={values.operation_city}
                  onChange={handleChange("operation_city")}
                />
              </div>
            )}
            {type === "individual" ? (
              <div {...getRootLicenseProps()}>
                <ProfileEditDatenCv
                  label="Upload License"
                  placeholder="Upload License"
                  value={values.license?.name || user?.license}
                  icon={<FileIcon />}
                  type="files"
                  {...getInputLicenseProps()}
                />
              </div>
            ) : (
              <div className="auto-complete-con">
                <CustomAutoComplete
                  id="organisation_role"
                  label="Role"
                  isClearable={false}
                  placeholder={"Select role"}
                  initOption={values.organisation_role}
                  customStyles={{
                    control: {
                      width: "100%",
                      borderRadius: "100px",
                    },
                  }}
                  options={orgList}
                  onChange={(e) => {
                    handleChangeSelectDnR(e);
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <hr />

        <div className="part">
          <h2>3. Marketing Preferences</h2>

          <p>
            We would like to get in touch with you with relevant information
            from time to time. Please select from the contact options below:
          </p>

          <div className="items">
            <ul>
              {items.map((item, index) => (
                <li key={index}>
                  <div className="item">
                    <span>{item}</span>
                    <label class="toggle-switch">
                      <input type="checkbox" />
                      <div class="toggle-switch-background">
                        <div class="toggle-switch-handle"></div>
                      </div>
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <div className="privacy">
            <p>
              Please note: You may still be contacted with emails and mobile
              messages relating to job applications you have made, or with
              relevant payroll, administration or service messages. For more
              details see our <span>Data Protection Policy</span> &{" "}
              <span>Privacy Policy</span>
            </p>
          </div>
        </div>

        <hr />

        <div className="part">
          <h2>4. Skills</h2>

          <div className="input-group">
            <div className="auto-complete-con">
              <CustomAutoComplete
                isClearable={false}
                placeholder="Enter your each skill"
                initOption={skill?.label}
                options={skillsList}
                customStyles={{
                  control: {
                    width: "100%",
                    borderRadius: "100px",
                  },
                }}
                onChange={(e) => setSkill(e)}
              />
            </div>
            <button onClick={handleAddSkill} className="add-skill">
              Add Skill
            </button>

            <div className="skills-lists">
              {values.skills?.length === 0 ? (
                <div>No skills added</div>
              ) : (
                values.skills?.map((i, index) => (
                  <div key={i} className="each-skill">
                    {i}
                    <span onClick={() => handleDeleteSkill(index)}>x</span>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>

        <button type="submit" onClick={handleSubmit}>
          <span>Update Now</span>
          <GoArrowRight />
        </button>
      </div>
    </div>
  );
};

export default memo(ProfileEdit);
