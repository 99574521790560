import React, { useState } from "react";
import "./EachJob.css";
import { CiLocationOn } from "react-icons/ci";
import { BsPlus } from "react-icons/bs";
import DashboardModal from "../dashboardModal/DashboardModal";
import CalendarIcon from "../../components/icon/CalendarIcon";
import TimeIcon from "../icon/TimeIcon";
import DatePickerInput from "../datePicker/DatePickerInput";
import TimeFrameInput from "./components/timeFrameInput/TimeFrameInput";

const EachJob = ({ job, status }) => {
  const [showTimesheetModal, setShowTimesheetModal] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);

  const [timesheetArray, setTimesheetArray] = useState([1])

  const addTimeSheet = () => {
    const lastnum = timesheetArray[timesheetArray.length - 1];
    setTimesheetArray((timesheetArray) => [...timesheetArray, lastnum + 1])
  };

  return (
    <>
      <div className="job-box">
        <div className="flex-head">
          <img src={job.oranizationIMG} alt="job-img" />

          <div>
            <h3>{job.organization}</h3>
            <div className="div-locate">
              <CiLocationOn className="job-location-icon" />
              <span className="job-location-text">{job.location}</span>
            </div>
          </div>
        </div>

        <div className="second-section">
          <div className="flex-second">
            <h3>{job.jobTitle}</h3>
            <span>N500/hr</span>
          </div>
          <p>{job.des}</p>
        </div>

        <div className="third-section">
          {status === "APPROVED" && (
            <button
              className="btn-one"
              onClick={() => setShowTimesheetModal(!showTimesheetModal)}
            >
              <span>Fill timesheet</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M5.33325 1.33334V3.33334"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.6667 1.33334V3.33334"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2.33325 6.06H13.6666"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14 5.66668V11.3333C14 13.3333 13 14.6667 10.6667 14.6667H5.33333C3 14.6667 2 13.3333 2 11.3333V5.66668C2 3.66668 3 2.33334 5.33333 2.33334H10.6667C13 2.33334 14 3.66668 14 5.66668Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.4632 9.13335H10.4692"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.4632 11.1334H10.4692"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.99691 9.13335H8.0029"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.99691 11.1334H8.0029"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.52962 9.13335H5.53561"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.52962 11.1334H5.53561"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          )}

          <button
            className="btn-two"
            onClick={() => setShowReviewModal(!showReviewModal)}
          >
            <span>Leave a review</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M9.15327 2.34001L10.3266 4.68668C10.4866 5.01334 10.9133 5.32668 11.2733 5.38668L13.3999 5.74001C14.7599 5.96668 15.0799 6.95334 14.0999 7.92668L12.4466 9.58001C12.1666 9.86001 12.0133 10.4 12.0999 10.7867L12.5733 12.8333C12.9466 14.4533 12.0866 15.08 10.6533 14.2333L8.65994 13.0533C8.29994 12.84 7.70661 12.84 7.33994 13.0533L5.34661 14.2333C3.91994 15.08 3.05327 14.4467 3.42661 12.8333L3.89994 10.7867C3.98661 10.4 3.83327 9.86001 3.55327 9.58001L1.89994 7.92668C0.926606 6.95334 1.23994 5.96668 2.59994 5.74001L4.72661 5.38668C5.07994 5.32668 5.50661 5.01334 5.66661 4.68668L6.83994 2.34001C7.47994 1.06668 8.51994 1.06668 9.15327 2.34001Z"
                stroke="#245293"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>

        <div className="status">
          <div>{job.status}</div>
        </div>
      </div>

      <DashboardModal isOpen={showTimesheetModal}>
        <div className="dashboard-modal">
          <h3>Locum Timesheet</h3>

          <div className="spacing-top box-timesheet">
            <h4>Locum Schedule</h4>

            <div className="timerange">
              <div className="each-timerange">
                <CalendarIcon />
                <p>1st October, 9:00AM - 4th October, 5PM</p>
              </div>

              <div className="middle">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="4"
                  height="4"
                  viewBox="0 0 4 4"
                  fill="none"
                >
                  <circle cx="2" cy="2" r="2" fill="#808080" />
                </svg>
              </div>
              <div className="each-timerange">
                <TimeIcon />
                <p>4 Days</p>
              </div>
            </div>
          </div>

          <div className="box-timesheet">
            <div className="overscroll-box-timesheet">
              {timesheetArray.map((each, index) => (
                <div key={index} className="box-timesheet-input-cover">
                  <TimeFrameInput label="Start date and time" />
                  <div className="to">to</div>
                  <TimeFrameInput label="End date and time" />
                  <div className="hours-bg">
                    <label>Hours</label>
                    <DatePickerInput type="text" />
                  </div>
                </div>
              ))}
            </div>

            <button onClick={addTimeSheet}>
              <BsPlus />
              <p>Add more rows</p>
            </button>
          </div>

          <div className="dashboard-modal-btn">
            <button
              onClick={() => setShowTimesheetModal(!showTimesheetModal)}
              className="btn-cancel"
            >
              Cancel
            </button>
            <button className="btn-submit">Submit Timesheet</button>
          </div>
        </div>
      </DashboardModal>

      <DashboardModal isOpen={showReviewModal}>
        <div className="dashboard-modal">
          <h3>Leave a Review of this facility</h3>
          <hr />
          <div className="review-logo">
            <div className="review-logo-img">
              <img src={job.oranizationIMG} alt="img" />
            </div>
            <div className="title-bg">
              <h4>{job.jobTitle}</h4>

              <div className="rate">
                <div>
                  {[1, 2, 3, 4, 5].map((num) => (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                    >
                      <path
                        d="M8.52222 0L10.4356 5.88871H16.6273L11.6181 9.52813L13.5315 15.4168L8.52222 11.7774L3.51299 15.4168L5.42634 9.52813L0.417108 5.88871H6.60886L8.52222 0Z"
                        fill="#E6A740"
                      />
                    </svg>
                  ))}
                </div>

                <span>5/5</span>
              </div>
            </div>
          </div>

          {/* experience review box */}
          <div className="experience-review-box">
            <p>Enter Your Experience</p>
            <textarea
              cols="30"
              rows="10"
              placeholder="Please enter your rating and experience of your time at this facility"
            ></textarea>
            {/* <input type="text" placeholder='Please enter your rating and experience of your time at this facility' /> */}
          </div>
          {/* experience review box */}

          {/* cancel and confirm button */}
          <div className="dashboard-modal-btn">
            <button
              onClick={() => setShowReviewModal(!showReviewModal)}
              className="btn-cancel"
            >
              Cancel
            </button>
            <button className="btn-submit">Submit Timesheet</button>
          </div>
          {/* cancel and confirm button */}
        </div>
      </DashboardModal>
    </>
  );
};

export default EachJob;
