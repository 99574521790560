import React from "react";
import "./PostedJobs.css";
import { jobsArray } from "../../../components/findJobs/Jobs";
import JobItem from "../../../components/jobItem/JobItem";
import { useNavigate } from "react-router-dom";

const PostedJobs = () => {
  const navigate = useNavigate()
  
  return (
    <div className="Posted-jobs-container">
      <div className="posted-jobs-right">
        <h2>Posted Jobs</h2>
        <div className="posted-jobs-content">
          {jobsArray.map((job, index) => {
            return (
              <div onClick={() => navigate(`/joblistings/${job.Id}/posted`)} className="postJobs-jobItem" key={index}>
                <JobItem
                  title={job.jobTitle}
                  jobDesc={job.des}
                  rate={job.rate}
                  jobLoc={job.location}
                  jobImg={job.oranizationIMG}
                  jobOrg={job.organization}
                  jobOrgloc={job.organizationLocation}
                  jobId={job.Id}
                />
              </div>
            );
          })}
        </div>
      </div>
      {/* posted jobs right side */}
    </div>
  );
};

export default PostedJobs;
