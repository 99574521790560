import React from "react";
import "./ProfilePage.css";
import { CiEdit } from "react-icons/ci";
import { Link } from "react-router-dom";
import ViewsIcon from "../../../components/icon/ViewsIcon";
import { useTypedSelector } from "../../../hook/useTypedSelector";
import Skeleton from "@mui/material/Skeleton";
import { isEmpty } from "../../../utils";
import { config } from "../../../config";

const ProfilePage = () => {
  const user = useTypedSelector((store) => store.user);

  const separatedNames = user?.name && user?.name.split(" ");

  const profilePix =
    !isEmpty(user) && `${config.getPublicImages}/${user?.profile_pix}`;

  const file = !isEmpty(user) && `${config.getPublicImages}/${user?.cv}`;

  return (
    <div className="profile-page-container">
      <h6>My Profile</h6>
      <div className="profile-page-details">
        <div className="profile-box-1">
          <div className="profile-nameBox">
            {isEmpty(user) ? (
              <Skeleton variant="circular" width={100} height={100} />
            ) : user?.profile_pix === "" ? (
              <div className="nameBox-image">
                <img
                  src={
                    "https://static.vecteezy.com/system/resources/thumbnails/002/534/006/small/social-media-chatting-online-blank-profile-picture-head-and-body-icon-people-standing-icon-grey-background-free-vector.jpg"
                  }
                  alt="profile"
                />
              </div>
            ) : (
              <div className="nameBox-image">
                <img src={profilePix} alt="profile" />
              </div>
            )}

            <div className="nameBox-details">
              {isEmpty(user) ? (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1rem" }}
                  width={100}
                />
              ) : (
                <p className="nameBox-fullname">
                  {user?.name?.charAt(0).toUpperCase() + user?.name.slice(1)}
                </p>
              )}

              {isEmpty(user) ? (
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={70} />
              ) : (
                <>
                  <p className="nameBox-job">{user?.organisation_role?.name}</p>
                  <p className="nameBox-job">{user?.division?.name}</p>
                </>
              )}

              {isEmpty(user) ? (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1rem" }}
                  width={150}
                />
              ) : (
                <p className="nameBox-location">
                  {user?.state}, {user?.country}
                </p>
              )}
            </div>
          </div>
          <div className="profile-editButton">
            {user?.account_type && (
              <Link to={`/settings/profileedit/${user?.account_type}`}>
                <button>
                  <CiEdit /> Edit
                </button>
              </Link>
            )}
          </div>
        </div>

        <div className="profile-box-2">
          <div className="profile-box2-top">
            <p className="heading">Personal Information</p>
          </div>
          <div className="profile-box2-bottom">
            <div className="entry-1">
              <h5>First Name</h5>
              {isEmpty(user) ? (
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={80} />
              ) : (
                <p>
                  {user?.name &&
                    separatedNames[0]?.charAt(0).toUpperCase() +
                      separatedNames[0]?.slice(1)}
                </p>
              )}
            </div>
            <div className="entry-1">
              <h5>Last Name</h5>
              {isEmpty(user) ? (
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={80} />
              ) : (
                <p>
                  {user?.name &&
                    separatedNames.length > 0 &&
                    separatedNames[1]?.charAt(0).toUpperCase() +
                      separatedNames[1]?.slice(1)}
                </p>
              )}
            </div>
            <div className="entry-1">
              <h5>Email Address</h5>
              {isEmpty(user) ? (
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={80} />
              ) : (
                <p>{user?.email}</p>
              )}
            </div>
            <div className="entry-1">
              <h5>Bio</h5>
            </div>
            <div className="entry-1">
              <h5>Phone No</h5>
              {isEmpty(user) ? (
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={80} />
              ) : (
                user?.phone && <p>+{user?.phone}</p>
              )}
            </div>
          </div>
        </div>

        <div className="profile-box-3">
          <div className="profile-box3-top">
            <p className="heading">Address</p>
          </div>

          <div className="profile-box3-bottom">
            <div className="leftbox">
              <div className="entry-1">
                <h5>Country</h5>
                {isEmpty(user) ? (
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "1rem" }}
                    width={80}
                  />
                ) : (
                  <p>{user?.country}</p>
                )}
              </div>
              <div className="entry-1">
                <h5>Postal Code</h5>
                {isEmpty(user) ? (
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "1rem" }}
                    width={80}
                  />
                ) : (
                  <p>{user?.postal_code}</p>
                )}
              </div>
            </div>
            <div className="rightbox">
              <div className="entry-1">
                <h5>City/State</h5>
                {isEmpty(user) ? (
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "1rem" }}
                    width={80}
                  />
                ) : (
                  <p>{user?.state}</p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="profile-box-5">
          <div className="profile-box5-top">
            <p className="heading">Resume</p>
            <div className="profile-editButton">
              <Link to={file && file}>
                <button>
                  <ViewsIcon color={"#000"} /> View
                </button>
              </Link>
            </div>
          </div>
          <div className="profile-box5-bottom">
            {isEmpty(user) ? (
              <Skeleton variant="rectanglar" width={300} height={300} />
            ) : file.trim() === "" ? (
              <p>No Resume Uploaded</p>
            ) : (
              <iframe title="cv" width="50%" height="300" src={file} />
            )}
          </div>
        </div>

        <div className="profile-box-6">
          <div className="profile-box6-top">
            <p className="heading">Skills</p>
          </div>
          <div className="profile-box6-bottom">
            {isEmpty(user) ? (
              <>
                <Skeleton variant="text" sx={{ fontSize: "3rem" }} width={80} />
                <Skeleton variant="text" sx={{ fontSize: "3rem" }} width={80} />
              </>
            ) : (
              user?.skills.map((skill, i) => <span key={i}>{skill}</span>)
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
