import React, { memo } from "react";
import logo from "../../assets/logo-profile.png";
import { BsArrowRight } from "react-icons/bs";
import { Link, NavLink } from "react-router-dom";
import "./DashboardDrawer.css";
import { PiDotsThreeOutline } from "react-icons/pi";
import useLogout from "../../hook/useLogout";

const DashboardDrawer = ({ navList }) => {
  const logout = useLogout();
  return (
    <div className="dashboard-Drawer-container">
      <div className="profile-left">
        <div className="upper">
          <Link to="/" className="desktop">
            <div className="logo-img">
              <img src={logo} alt="" />
            </div>
          </Link>

          <div className="home-box-one-button desktop">
            <button>
              Find Jobs <BsArrowRight />
            </button>
          </div>

          <div className="dashboard-options">
            <div className="box-1">
              {navList?.map((nav) => (
                <NavLink
                  key={nav.id}
                  to={nav.to}
                  className={(navClass) =>
                    navClass.isActive ? "profile-active" : "profile-dom"
                  }
                  onClick={() => {
                    if (nav.name === "Logout") logout();
                  }}
                >
                  <div className="profile-button">
                    {nav.icon}
                    <span>{nav.name}</span>
                  </div>
                </NavLink>
              ))}
            </div>
          </div>
        </div>

        <div className="home-box-one-button mobile">
          <button>
            Find Jobs <BsArrowRight />
          </button>
        </div>

        <div className="box-2">
          <div className="first">
            <div className="profile-pics" />

            <div className="box-2-text">
              <span>Adewole</span>
              <h5>Admin</h5>
            </div>
          </div>

          <PiDotsThreeOutline />
        </div>
      </div>
    </div>
  );
};

export default memo(DashboardDrawer);
