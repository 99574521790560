import React, { memo } from "react";
import "./ProfileEditInput.css";

const ProfileEditInput = ({
  label,
  placeholder,
  width,
  widthCon,
  customLabelStyles,
  customInputStyles,
  type,
  id,
  value,
  onChange,
  touched,
  error,
}) => {
  const styles = {
    bgInput: {
      width: "100%",
      position: "relative",
    },
    input: {
      width: width,
      padding: "0.5rem",
      borderRadius: "20px",
      border:
        error && touched ? "1px solid red" : "1px solid rgb(236, 236, 236)",
      paddingLeft: "15px",
      fontSize: "14px",
      lineHeight: "24px",
      fontWeight: 500,
      color: "#000",
      outline: "none",
      ...customInputStyles,
    },
    error: {
      fontSize: "10px",
      color: "red",
      paddingLeft: "15px",
    },
  };

  return (
    <div style={{ width: widthCon }} className="customInput-contain">
      {label && (
        <label
          style={{
            color: "#777",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "24px",
            ...customLabelStyles,
          }}
        >
          {label}
        </label>
      )}
      <div style={styles.bgInput}>
        <input
          id={id}
          value={value}
          onChange={onChange}
          type={type}
          style={styles.input}
          placeholder={placeholder}
        />
        {touched && error && <span style={styles.error}>{error}</span>}
      </div>
    </div>
  );
};

export default memo(ProfileEditInput);
