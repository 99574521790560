import React, { useState } from "react";
import "./JobActivity.css";
import { jobsArray } from "../../../components/findJobs/Jobs";
import EachJob from "../../../components/activity/EachJob";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const JobActivity = (props) => {
  const [status, setStatus] = useState("APPROVED");
  const navigate = useNavigate();

  const filterJobs = jobsArray.filter((job) => job.status === status);

  return (
    <div className="job-activity-container">
      <div className="job-activity-right">
        <div className="job-activity-right-top">
          <div className="activity-box-head">
            <h2>Job Activity</h2>
            <button onClick={() => navigate('/postjob')} className="link">
              <p>Post a Job</p>
            </button>
          </div>
        </div>
        
        <div className="job-activity-right-bottom">
          <div className="job-activity-box">
            <div className="job-activity-tabs">
              <span
                onClick={() => setStatus("APPROVED")}
                className={status === "APPROVED" ? "active" : ""}
              >
                Approved
              </span>
              <span
                onClick={() => setStatus("PENDING")}
                className={status === "PENDING" ? "active" : ""}
              >
                Pending
              </span>
              <span
                onClick={() => setStatus("REJECTED")}
                className={status === "REJECTED" ? "active" : ""}
              >
                Rejected
              </span>
              <span
                onClick={() => setStatus("SAVED")}
                className={status === "SAVED" ? "active" : ""}
              >
                Saved
              </span>
              <span
                onClick={() => setStatus("APPLIED")}
                className={status === "APPLIED" ? "active" : ""}
              >
                Applied
              </span>
            </div>
            <div className="job-activity-content">
              {filterJobs?.map((job) => {
                return (
                  <div className="job-activity-jobItem">
                    <EachJob job={job} status={status} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobActivity;
