import React from "react";

const TimeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10.0007 18.4583C14.6662 18.4583 18.459 14.6655 18.459 9.99996C18.459 5.33444 14.6662 1.54163 10.0007 1.54163C5.33513 1.54163 1.54232 5.33444 1.54232 9.99996C1.54232 14.6655 5.33513 18.4583 10.0007 18.4583ZM10.0007 1.79163C14.5268 1.79163 18.209 5.47382 18.209 9.99996C18.209 14.5261 14.5268 18.2083 10.0007 18.2083C5.47451 18.2083 1.79232 14.5261 1.79232 9.99996C1.79232 5.47382 5.47451 1.79163 10.0007 1.79163Z"
        fill="#808080"
        stroke="#808080"
      />
      <path
        d="M6.94843 12.7673L6.95883 12.7603L6.96955 12.7539L9.55269 11.2124C9.55275 11.2124 9.55282 11.2123 9.55289 11.2123C10.043 10.9193 10.4258 10.241 10.4258 9.67497V6.2583C10.4258 6.22815 10.4135 6.19687 10.3879 6.17123C10.3622 6.14559 10.3309 6.1333 10.3008 6.1333C10.2706 6.1333 10.2394 6.14559 10.2137 6.17123C10.1881 6.19687 10.1758 6.22815 10.1758 6.2583V9.67497C10.1758 9.94333 10.0718 10.2156 9.95311 10.4242C9.83533 10.6313 9.65468 10.8608 9.42149 10.9973L6.84034 12.5377L6.84036 12.5377L6.83605 12.5402C6.78733 12.5686 6.75856 12.6375 6.79573 12.7082C6.82945 12.7576 6.87539 12.775 6.90911 12.775C6.92482 12.775 6.93386 12.7732 6.93821 12.772C6.9419 12.771 6.94471 12.7698 6.94843 12.7673Z"
        fill="#292D32"
        stroke="#808080"
      />
    </svg>
  );
};

export default TimeIcon;
