import React, { useState } from 'react'
import LicensePage from '../licenseUpload/LicensePage'
import SignUp from '../signUp/SignUp'
import useSignUp from '../../../hook/useSignUp'

const Register = () => {
    const [moveToLicense, setMoveToLincense] = useState(false)
    const formik = useSignUp();

  return moveToLicense ? (
    <LicensePage formik={formik} />
  ) : (
    <SignUp formik={formik} onNext={() => setMoveToLincense(true)} />
  );
}

export default Register