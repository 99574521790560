export const setLocalStorageWithExpiration = (
  key,
  values,
  expirationInHours
) => {
  const expirationTime =
    new Date().getTime() + expirationInHours * 60 * 60 * 1000;
  const data = { values, expirationTime };
  localStorage.setItem(key, JSON.stringify(data));
};

export const getLocalStorageWithExpiration = (key) => {
  const data = JSON.parse(localStorage.getItem(key));
  if (!data) return null;

  const { values, expirationTime } = data;
  const currentTime = new Date().getTime();

  if (currentTime > expirationTime) {
    localStorage.removeItem(key);
    return null;
  }

  return values;
};

export const isEmpty = (obj) => {
  return Object.values(obj).length === 0;
};

export const errorProps = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
};

export const successProps = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};
