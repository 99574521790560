import { useCallback } from "react";
import getOr from "lodash/fp/getOr";
import iso3166 from "iso-3166-2";

function useGenerateProvincesList() {
  return useCallback((countryCode = "") => {
    const sub = getOr({}, "sub", iso3166.country(countryCode));
    const entries = Object.entries(sub);
    return entries.map((entry) => {
      const splittedCode = entry[0].split("-");
      return {
        code: splittedCode[1],
        name: entry[1] ? entry[1].name : "unknown province",
      };
    });
  }, []);
}

export default useGenerateProvincesList;
