import React, { memo, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import DashboardDrawer from "../../components/dashboardDrawer/DashboardDrawer";
import "./RegularDashboard.css";
import DashboardNavbarMobile from "../../components/dashboardNavbarMobile/DashboardNavbarMobile";
import DashboardHeadIcon from "../../components/dashboardHeadIcon/DashboardHeadIcon";
import { getLocalStorageWithExpiration } from "../../utils";
import useAuth from "../../hook/useAuth";
import { Bars } from "react-loader-spinner";

const RegularDashboard = ({ navItems }) => {
  const navigate = useNavigate();
  const key = "user-values";
  const access_token = getLocalStorageWithExpiration(key)
  const {loading} = useAuth();

  useEffect(() => {
    if (access_token === null) {
      navigate("/");
    }
  }, [access_token]);

  if(loading) {
    return (
      <div className="loading-screen">
        <Bars
          height="80"
          width="80"
          color=" #245293"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  return (
    <>
      <div className="dash-desktop">
        <DashboardDrawer navList={navItems} />
      </div>
      <div className="dash-mobile">
        <DashboardNavbarMobile navList={navItems} />
      </div>
      <div className="regular-dashboard">
        <div className="inner">
          <div className="right-icons">
            <DashboardHeadIcon />
          </div>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default memo(RegularDashboard);
