import { configureStore } from "@reduxjs/toolkit";
import { userSlice } from "./user/User.slice";

export const store = configureStore({
  reducer: {
    user: userSlice.reducer,
  },
  //   preloadedState: loadStateFromLocalStorage(),
  //   middleware: (getDefaultMiddleware) =>
  //     getDefaultMiddleware().concat(
  //       // api.middleware,
  //       api2.middleware,
  //       contentApi.middleware,
  //       newsApi.middleware,
  //       localStorageMiddleware
  //     ),
});

export default store
