import { useFormik } from "formik";
import * as Yup from "yup";
import { useTypedSelector } from "./useTypedSelector";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  errorProps,
  getLocalStorageWithExpiration,
  successProps,
} from "../utils";
import useAuth from "./useAuth";
import { updateUserDetails } from "../endpoints/user";

const useUpdateUser = () => {
  const user = useTypedSelector((store) => store.user);
  const key = "user-values";
  const navigate = useNavigate();
  const { getUserDetails } = useAuth();

  //console.log("user", user);

  const {
    name,
    email,
    phone,
    about_us,
    address,
    career_city,
    career_state,
    operation_city,
    operation_state,
    city,
    state,
    country,
    date_of_birth,
    postal_code,
    skills,
  } = user;

  const { type } = useParams();

  const handleSubmit = async (values) => {
    const { token, acc_type } = getLocalStorageWithExpiration(key);
    try {
      const { data, status } = await updateUserDetails(values, token, acc_type);
      console.log("data", data);

      if (status === 202 || status === 200 || data.status === true) {
        getUserDetails({ token, acc_type });
        toast.success("Updated details", successProps);
        navigate("/dashboard");
      }
    } catch (error) {
      if (error.message === "Network Error") {
        toast.error(error.message, errorProps);
      } else if (error.response.status === 400) {
        toast.error("Validation error: Please fill all fields", errorProps);
      } else {
        toast.error("Failed to update", errorProps);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      name,
      email,
      phone,
      about_us,
      address,
      career_city,
      career_state,
      operation_city,
      operation_state,
      organisation_role: null,
      city,
      state,
      country,
      cv: null,
      date_of_birth,
      division: null,
      postal_code,
      profile_pix: null,
      skills,
      license: null,
      _method: "PUT",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(6, "Username must at least be 5 characters")
        .max(20, "Username must be less than or equal to 10")
        .required("Required"),
    }),
    onSubmit: (values) => {
      if (type === "organisation") {
        const {
          career_city,
          career_state,
          division,
          license,
          organisation_role,
          ...restOr
        } = values;
        const mainValues = {
          organisation_role_id: organisation_role?.value,
          ...restOr,
        };
        handleSubmit(mainValues);
      } else if (type === "individual") {
        const {
          operation_city,
          operation_state,
          organisation_role,
          division,
          ...restIn
        } = values;
        const mainValues = {
          division_id: division?.value,
          ...restIn,
        };
        handleSubmit(mainValues);
      }
    },
  });

  return formik;
};

export default useUpdateUser;
