import React from "react";
import pics from "../../../../../assets/message-pics.png";
import "./EachChat.css";

const EachChat = ({ owner, text }) => {
  return (
    <div
      style={
        owner
          ? { justifyContent: "flex-end" }
          : { justifyContent: "flex-start" }
      }
      className="eachchat-bg"
    >
      {!owner && (
        <div className="img-cover">
          <img src={pics} alt="pics" />
        </div>
      )}
      <div className={`chat-text ${owner ? "right" : "left"}`}>
        <p>
          {text}
        </p>
      </div>
    </div>
  );
};

export default EachChat;
