import React, { useCallback, useState } from "react";
import "./LicensePage.css";
import Logo from "../../../assets/fmlogo-login.png";
import Doctor from "../../../assets/doc-2.png";
import whiteBox from "../../../assets/white-box.png";
import { Link } from "react-router-dom";
import DownloadIcon from "../../../components/icon/DownloadIcon";
import { useDropzone } from "react-dropzone";
import { ToastContainer, toast } from "react-toastify";
import { errorProps } from "../../../utils";

const LicensePage = ({ formik }) => {
  const [license, setLicense] = useState(null);
  const onDrop = useCallback((files) => {
    setLicense(
      ...files.map((file) =>
        Object.assign(file, { preview: URL.createObjectURL(file) })
      )
    );
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className="license-container">
      {/* login left */}
      <div className="license-left">
        <div className="license-left-content">
          {/* logo */}
          <Link to="/">
            <div className="logo">
              <img src={Logo} alt="logo" />
            </div>
          </Link>
          {/* logo */}

          <div className="license-left-text">
            <h3>
              Embrace flexible and rewarding practice through healthcare locum
            </h3>
            <p>
              Join the largest community of healthcare professionals on locum
              staffing in Africa as a Healthcare professional; and kick-start a
              flexible and rewarding practice.
            </p>
          </div>
          <div className="license-left-image">
            <img className="white-box" src={whiteBox} alt="" />
            <img className="doc" src={Doctor} alt="" />
          </div>
        </div>
      </div>
      {/* login left */}

      {/* login right starts*/}
      <div className="license-right">
        <div className="license-right-body">
          <div className="license-right-content">
            <h2>
              Upload a copy of <br /> your practice license
            </h2>
            <p className="head-desc">
              We need to verify that you are eligible to use this platform.
              <br />
              Note: your pratice license is NOT disclosed to any third party.
            </p>

            <div
              className="bg-file-license"
              {...getRootProps()}
              style={
                license
                  ? { border: "dashed 4px #245293" }
                  : { border: "dashed 4px #808080" }
              }
            >
              <input type="files" {...getInputProps()} />
              {license ? (
                <div className="license-img-cover">
                  <img
                    src={license.preview}
                    alt="license-file"
                    width={"100%"}
                    height={"100%"}
                  />
                  <div className="upload-btn-bg">
                    <button>Upload a new file</button>
                  </div>
                </div>
              ) : (
                <div className="inner-bg-file-license">
                  <DownloadIcon />

                  <h4>Upload or drop your file here</h4>
                  <p>PDF, Doc, up to 10MB.</p>

                  <button>Choose a file</button>
                </div>
              )}
            </div>

            <div className="bg-license">
              <button
                type="submit"
                className="license-btn"
                onClick={() => {
                  if (license) {
                    formik.setFieldValue("license", license);
                    formik.setFieldValue("account_type", "individual");
                    formik.handleSubmit();
                  } else {
                    toast.warn(
                      "Please add your license file to continue as an individual or click on skip to register as an organisation",
                      errorProps
                    );
                  }
                }}
              >
                Continue
              </button>
            </div>

            <div className="skip-bg">
              <span
                className="skip-btn"
                onClick={() => {
                  formik.setFieldValue("license", null);
                  formik.setFieldValue("account_type", "organisation");
                  formik.handleSubmit();
                }}
              >
                Skip
              </span>
              <span className="skip-text"> for Hiring Manager</span>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      {/* login right ends*/}
    </div>
  );
};

export default LicensePage;
