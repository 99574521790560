import React, { memo } from "react";
import "./FilterInput.css";

const FilterInput = ({ placeholder, type, endIcon, customStyles }) => {
  return (
    <div style={customStyles?.bg} className="input-box-1">
      <input style={customStyles?.input} type={type} placeholder={placeholder} />
      {endIcon && endIcon}
    </div>
  );
};

export default memo(FilterInput);
