import { useEffect, useState } from "react";
import { useActions } from "./useActions";
import { errorProps, getLocalStorageWithExpiration } from "../utils";
import { toast } from "react-toastify";
import { getUser } from "../endpoints/user";

const key = "user-values";

const useAuth = () => {
  const [loading, setLoading] = useState(false)
  const actions = useActions();
  const [keyValues] = useState(
    getLocalStorageWithExpiration(key)
  );

  const getUserDetails = async (values) => {
    const { token, acc_type } = values;
    setLoading(true)

    try {
      const { data, status } = await getUser(token, acc_type);
      if (status === 201 || status === 200 || data.status === true) {
        actions.setUser({ ...data.data });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false)
      actions.setUser({});
      if (error.message === "Network Error") {
        toast.error(error.message, errorProps);
      } else {
        toast.error("Can not get user", errorProps);
      } 
    }
  };

  useEffect(() => {
    if(keyValues) getUserDetails(keyValues);
  }, [keyValues]);

  return { loading, getUserDetails };
};

export default useAuth;
