import React, { memo } from "react";
import "./ModalBtn.css";

const ModalBtn = ({ title, handleClick }) => {
  return (
    <button className="modal-btn-own" onClick={handleClick}>
      {title}
    </button>
  );
};

export default memo(ModalBtn);
