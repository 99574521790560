import React from 'react'
import "./ClientSlider.css"
import clientPic from "../../assets/client.png"

const ClientSlider = () => {
    return (
        <div className='client-slider-container'>
            <h3 className='client-speak'>Our Clients Speak</h3>
            
            <div className="customer-box-container">

                {/* customer cards */}
                <div className="customer-item" data-aos="fade-right">
                    <div className="customer-content-top">
                        <h4>Incredible Experience</h4>
                        <p>We had an incredible experience working with Freelance Medic and were impressed they made such
                            a big difference in only three weeks. Our team is so grateful for the wonderful improvements
                            they made and their ability to get familiar with the concept so quickly.
                            It acted as a catalyst to take our design to the next level and get more eyes on our product.
                        </p>
                    </div>
                    <div className="customer-content-bottom">
                        <div className="customer-img">
                            <img src={clientPic} alt="client" />
                        </div>
                        <div className="customer-extra">
                            <h5 className="customer-name">
                                jane cooper
                            </h5>
                            <h5 className="customer-title">
                                ceo ABC cooperations
                            </h5>
                        </div>
                    </div>
                </div>
                {/* customer cards */}


                {/* customer card 2 */}
                <div className="customer-item" data-aos="fade-left">
                    <div className="customer-content-top">
                        <h4>Incredible Experience</h4>
                        <p>We had an incredible experience working with Freelance Medic and were impressed they made such
                            a big difference in only three weeks. Our team is so grateful for the wonderful improvements
                            they made and their ability to get familiar with the concept so quickly.
                            It acted as a catalyst to take our design to the next level and get more eyes on our product.
                        </p>
                    </div>
                    <div className="customer-content-bottom">
                        <div className="customer-img">
                            <img src={clientPic} alt="client" />
                        </div>
                        <div className="customer-extra">
                            <h5 className="customer-name">
                                jane cooper
                            </h5>
                            <h5 className="customer-title">
                                ceo ABC cooperations
                            </h5>
                        </div>
                    </div>
                </div>
                {/* customer card 2 */}

            </div>
        </div>
    )
}


export default ClientSlider