import React from "react";
import "./JobDetails.css";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import { CiSearch, CiLocationOn } from "react-icons/ci";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin5Line } from "react-icons/ri";
import { RxMagicWand } from "react-icons/rx";
import { GoCalendar } from "react-icons/go";
import { BsClock, BsBookmark } from "react-icons/bs";
import { VscCompass } from "react-icons/vsc";
import { Autocomplete } from "@mui/material";

const JobDetails = () => {
  const navigate = useNavigate()
  const { id, posted } = useParams();
  const top100Films = [];

  // const { jobTitle, rate, location, des, jobImg, organization, organizationLocation, Id } = jobsArray[params.id - 1]

  return (
    <>
      <Navbar />

      {/* search bar */}
      <div className="jobDetails-searchbar-container">
        <div className="joblistings-searchbar">
          <div className="findJobs-search-box">
            {/* job title search input */}
            <div className="input-1">
              <CiSearch />

              <Autocomplete
                id="free-solo-demo"
                freeSolo
                sx={{ width: "240px", height: "20px" }}
                options={top100Films.map((option) => option.title)}
                renderInput={(params) => (
                  <div ref={params.InputProps.ref}>
                    <input
                      placeholder="Job Title"
                      type="text"
                      {...params.inputProps}
                      style={{ backgroundColor: "transparent" }}
                    />
                  </div>
                )}
              />
            </div>
            {/* job title search input */}

            {/* location search input */}
            <div className="input-1">
              <CiLocationOn />
              <input
                type="text"
                placeholder="Location"
                //onChange={(e) => setJobTitle(e.target.value)}
                // value={searchTermTitle}
              />
            </div>
            {/* location search input */}

            {/* find jobs button */}
            <div onClick={() => {}} className="findJobs-button">
              <button>
                Find Jobs <FaRegArrowAltCircleRight />
              </button>
            </div>
            {/* find jobs button */}
          </div>
        </div>
      </div>
      {/* search bar */}

      <div className="jobDetails-container">
        <div className="jobDetails-top">
          {/* left */}
          <div className="jobDetails-desc">
            <h3>Locum Pharmacist Job Description</h3>
            <div className="specialty">
              <RxMagicWand className="jobDetails-icon" />
              <span>Specialty</span>
            </div>
            <div className="specialty">
              <CiLocationOn className="jobDetails-icon" />
              <span>Location</span>
            </div>
            <div className="specialty">
              <GoCalendar className="jobDetails-icon" />
              <span>Start Date</span>
            </div>
            <div className="specialty">
              <BsClock className="jobDetails-icon" />
              <span>Contract Length</span>
            </div>
            <div className="specialty">
              <VscCompass className="jobDetails-icon" />
              <span>Pay Rate</span>
            </div>
          </div>
          {/* left */}

          {/* right */}
          {posted ? (
            <div className="jobDetails-apply">
              <div className="findJobs-button">
                <button className="bookmark" onClick={() => navigate('/postjob')}>
                  Edit <CiEdit />
                </button>
              </div>
              <div className="findJobs-button">
                <button>
                  Delete <RiDeleteBin5Line />
                </button>
              </div>
            </div>
          ) : (
            <div className="jobDetails-apply">
              <div className="findJobs-button">
                <button>
                  Apply Now <FaRegArrowAltCircleRight />
                </button>
              </div>
              <div className="findJobs-button">
                <button className="bookmark">
                  Save For Later <BsBookmark />
                </button>
              </div>
            </div>
          )}
          {/* right */}
        </div>

        {/* requirements */}
        {/* requirements */}
        <div className="jobDetails-bottom">
          <div className="jobDetails-requirements">
            <p>
              Previous Community Pharmacy Experience Preferred (Not Compulsory)
            </p>

            <div className="requirements">
              <h3>Requirements</h3>
              <ul>
                <li>
                  Excellent communication and interpersonal skills to build
                  relationships with patients, healthcare professionals, and
                  staff.
                </li>
                <li>
                  Strong leadership and management skills to lead and motivate
                  the pharmacy team.
                </li>
                <li>
                  Attention to detail and strong organizational skills to ensure
                  accuracy in medication dispensing and record-keeping.
                </li>
                <li>
                  Ability to prioritize tasks and manage time effectively to
                  meet deadlines.{" "}
                </li>
                <li>
                  Knowledge of pharmacy laws, regulations, and standards to
                  ensure compliance with all requirements
                </li>
                <li>
                  Proficiency in computer systems and software to manage
                  inventory, record-keeping, and communication with healthcare
                  professionals and patients
                </li>
                <li>
                  Knowledge of medication therapy and disease management to
                  provide accurate and relevant advice to patients and
                  healthcare professionals.
                </li>
              </ul>
            </div>

            <div className="requirements">
              <h3>Responsibilities</h3>
              <ul>
                <li>
                  Ensure compliance with all pharmacy laws, regulations, and
                  standards
                </li>
                <li>
                  Manage and supervise all aspects of the pharmacy operations
                  including dispensing, inventory management, and customer
                  service
                </li>
                <li>
                  Provide guidance and support to other pharmacy staff including
                  pharmacists and pharmacy technicians
                </li>
                <li>
                  Develop and maintain relationships with healthcare
                  professionals, including doctors and nurses, to ensure the
                  pharmacy is meeting the needs of the local community
                </li>
                <li>
                  Implement quality assurance and control programs to ensure
                  medication safety and accuracy
                </li>
                <li>
                  Manage the pharmacy budget and work with the management team
                  to develop and implement business strategies
                </li>
                <li>
                  Manage the pharmacy budget and work with the management team
                  to develop and implement business strategies
                </li>
                <li>
                  Ensure that all documentation, including patient records and
                  prescription information, is accurate and up-to-date
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* requirements */}
        {/* requirements */}
      </div>
    </>
  );
};

export default JobDetails;
