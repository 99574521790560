import React, { useMemo } from "react";
import "./Shortlist.css";
import { jobsArray } from "../../../components/findJobs/Jobs";
import JobItem from "../../../components/jobItem/JobItem";
import { useNavigate } from "react-router-dom";
import CustomAutoComplete from "../profile/components/customAutoComplete/CustomAutoComplete";
import useCountries from "../../../hook/useCountries";
import toLower from "lodash/fp/toLower";
import FilterInput from "../../../components/filterBox/FilterInput";
import useSortAlphabetically from "../../../hook/useSortAlphabetically";
import useGenerateProvincesList from "../../../hook/useGenerateProvincesList";

const Shortlist = () => {
  const navigate = useNavigate();
  const sortAlphabetically = useSortAlphabetically();
  const generateProvincesList = useGenerateProvincesList();

  const divisions = [
    {
      label: "Locum Pharmacist",
    },
    {
      label: "Locum Nurse",
    },
  ];

  const countries = useCountries();

  const countrySelectedOption = useMemo(() => {
    return countries.find((i) => toLower(i.value) === toLower("NG"));
  }, [countries]);

  const provinceOptions = useMemo(() => {
    return sortAlphabetically({
      items: generateProvincesList(countrySelectedOption?.code),
      byProperty: "name",
    }).map((i) => {
      return {
        value: i.code,
        label: i.name,
      };
    });
  }, [generateProvincesList, sortAlphabetically, countrySelectedOption]);

  const selectedProvinceOption = useMemo(
    () =>
      provinceOptions.find(
        (i) =>
          i.label.slice(0, 5) ===
          countrySelectedOption?.continent?.capital.slice(0, 5)
      ),
    [provinceOptions]
  );

  return (
    <div className="shortlist-container">
      <div className="shortlist-right">
        <div className="shortlist-right-bottom">
          <div className="shortlist-header">
            <h2>Shortlist</h2>

            <div className="shortlist-header-right">
              <div className="shortist-auto-complete">
                <CustomAutoComplete
                  isClearable={false}
                  placeholder={"Select division"}
                  options={divisions}
                  customStyles={{
                    control: {
                      width: "100%",
                      borderRadius: "0",
                    },
                  }}
                  // onChange={changeCountryHandler}
                />
              </div>

              <div className="shortist-auto-complete">
                <CustomAutoComplete
                  isClearable={false}
                  placeholder={"Select location"}
                  options={countries}
                  customStyles={{
                    control: {
                      width: "100%",
                      borderRadius: "4px",
                    },
                  }}
                  // onChange={changeCountryHandler}
                />
              </div>

              <div className="shortist-auto-complete">
                <CustomAutoComplete
                  isClearable={false}
                  placeholder={"Select province"}
                  options={provinceOptions}
                  
                  customStyles={{
                    control: {
                      width: "100%",
                      borderRadius: "4px",
                    },
                  }}
                  // onChange={changeCountryHandler}
                />
              </div>

              <div className="shortist-auto-complete">
                <FilterInput
                  customStyles={{
                    bg: {
                      height: "45px",
                      backgroundColor: "#fff",
                      borderRadius: "4px",
                    },
                    input: {
                      width: "100%",
                    },
                  }}
                  placeholder="DD-MM-YYY"
                  type={"date"}
                />
              </div>
            </div>
          </div>
          <div className="shortlist-right-content">
            <div className="job-list-container">
              {jobsArray.map((job) => {
                return (
                  <div
                    onClick={() => navigate(`/joblistings/${job.Id}`)}
                    className="shortlist-jobItem"
                  >
                    <JobItem
                      title={job.jobTitle}
                      jobDesc={job.des}
                      rate={job.rate}
                      jobLoc={job.location}
                      jobImg={job.oranizationIMG}
                      jobOrg={job.organization}
                      jobOrgloc={job.organizationLocation}
                      jobId={job.Id}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* shortlist bottom */}
      </div>
      {/* shortlist right side */}
    </div>
  );
};

export default Shortlist;
