import { useFormik } from "formik";
import * as Yup from "yup";
import { signUp } from "../endpoints/auth";
import { useActions } from "./useActions";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  errorProps,
  getLocalStorageWithExpiration,
  setLocalStorageWithExpiration,
  successProps,
} from "../utils";
import { getUser } from "../endpoints/user";

const key = "user-values";
const expirationHours = 24;

const useSignUp = () => {
  const navigate = useNavigate();
  const actions = useActions();

  const getUserDetails = async (values) => {
    const { token, acc_type } = values;

    try {
      const { data, status } = await getUser(token, acc_type);
      if (status === 201 || status === 200 || data.status === true) {
        actions.setUser({ ...data.data });
        navigate(`/settings/profileedit/${acc_type}`);
      }
    } catch (error) {
      if (error.message === "Network Error") {
        toast.error(error.message, errorProps);
      } else {
        toast.error("Can not get user", errorProps);
      }
    }
  };

  const handleSubmit = async (values) => {
    try {
      const { data, status } = await signUp(values);
      if (status === 201 || status === 200 || data.status === true) {
        toast.success("Successfully Registered", successProps);
        const values = {
          token: data?.token,
          acc_type: data?.data?.account_type,
        };
        setLocalStorageWithExpiration(key, values, expirationHours);
        const keyValues = getLocalStorageWithExpiration(key);
        if (keyValues) getUserDetails(keyValues);
      }
    } catch (error) {
      if (error.message === "Network Error") {
        toast.error(error.message, errorProps);
      } else if (error.response.status === 400) {
        toast.error("Email already exist", errorProps);
      } else {
        toast.error("Sign up failed", errorProps);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      password: "",
      account_type: "",
      confirm_password: "",
      license: null,
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      name: Yup.string()
        .min(6, "Fullname must at least be 6 chars, first and last name")
        .max(20, "Fullname must be less than or equal to 20")
        .required("Required"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
          "Password must contain at least one lowercase letter, one uppercase letter, and one number"
        )
        .required("Required"),
      confirm_password: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
      phone: Yup.number().required("Phone number is required"),
      email: Yup.string()
        .email("Provide vaild email address")
        .required("Required"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return formik;
};

export default useSignUp;
