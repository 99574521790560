import React, { useState } from "react";
import { AiOutlineStar } from "react-icons/ai";
import CvIcon from "../icon/CvIcon";
import Button from "@mui/material/Button";
import CustomMenu from "../customMenu/CustomMenu";

const SingleCandidates = ({ name, review, status, date, toggle }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const options =
    toggle === 3 ? ["Complaint", "Completed"] : ["Message", "Delete"];

  const complaintOptions = status === "Complaint" ? ["Resolve"] : options

  return (
    <div className="single-candidate">
      <div className="single-candidate-left">
        <h5>Name</h5>
        <h5>Review</h5>
        <h5>Status</h5>
        <h5>Date Applied</h5>
        <h5>Action</h5>
      </div>

      <div className="single-candidate-right">
        <span className="name">
          <span className="charAt">{name.charAt(0).toUpperCase()}</span>
          {name}
        </span>
        <span className="single review">
          <AiOutlineStar /> {review}
        </span>
        <span className="single review">{date}</span>
        <div className="status">
          <span
            className={
              status === "Pending"
                ? "pend"
                : status === "Successful"
                ? "succ"
                : status === "Rejected"
                ? "rej"
                : status === "Complaint"
                ? "rej"
                : status === "Completed"
                ? "succ"
                : status === "Ongoing"
                ? "pend"
                : null
            }
          >
            {status}
          </span>
        </div>

        {toggle === 2 ? (
          <span className="download">
            <CvIcon />
            TimeSheet
          </span>
        ) : (
          <span className="download">
            <CvIcon />
            View CV
          </span>
        )}
        <div className="dot-bg">
          <Button onClick={handleClick}>
            <span className="dot">...</span>
          </Button>
        </div>
        <CustomMenu
          anchorEl={anchorEl}
          handleClose={handleClose}
          options={complaintOptions}
        />
        <div className="mobile-options">
          {complaintOptions.map((option, index) => (
            <button
              key={index}
              className={`${index === 0 ? "message" : "delete"}`}
            >
              {option}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SingleCandidates;
