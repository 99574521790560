import { useFormik } from "formik";
import * as Yup from "yup";
import { forgetPassword } from "../endpoints/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { errorProps, successProps } from "../utils";

const useForgetPassword = () => {
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    try {
      const { data, status } = await forgetPassword(values);
      if (data.status === true) {
        toast.success(data?.message, successProps);
      } else if (data.status === false) {
        toast.error(data?.message, errorProps);
      }
    } catch (error) {
      toast.error("Network Problem, Try again later", errorProps);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Provide vaild email address")
        .required("Required"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return formik;
};

export default useForgetPassword;
