import React, { useEffect, useState } from "react";
import "./JobListings.css";
import Navbar from "../../components/navbar/Navbar";
import { CiSearch, CiLocationOn } from "react-icons/ci";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import JobItem from "../../components/jobItem/JobItem";
import { jobsArray } from "../../components/findJobs/Jobs";
import FilterBox from "../../components/filterBox/FilterBox";
import { Autocomplete } from "@mui/material";

const JobListings = () => {
  const [location, setLocation] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [openFilterBox, setOpenFilterBox] = useState(false);
  const [Jobs, setJobs] = useState(jobsArray);

  const top100Films = [];

  // useEffect(() => {
  //   const searchedTitle = jobsArray?.filter((job) => {
  //     return job.location
  //       ?.toLowerCase()
  //       .includes(searchTermLocation?.toLowerCase());
  //   });

  //   const searchedLocation = searchedTitle?.filter((job) => {
  //     return job.jobTitle
  //       ?.toLowerCase()
  //       .includes(searchTermTitle?.toLowerCase());
  //   });
  //   if (searchedLocation.length === 0) {
  //     setJobs(jobsArray);
  //   } else {
  //     setJobs(searchedLocation);
  //   }
  // }, [location, jobTitle]);

  // const searchJobsHandler = () => {
  //   const searchedTitle = jobsArray?.filter((job) => {
  //     return job.location
  //       .toLowerCase()
  //       .includes(searchTermLocation.toLowerCase());
  //   });
  //   const searchedLocation = searchedTitle?.filter((job) => {
  //     return job.jobTitle.toLowerCase().includes(searchTermTitle.toLowerCase());
  //   });
  //   setJobs(searchedLocation);
  // };

  return (
    <>
      {/* navbar */}
      <Navbar showSearch filter />
      {/* navbar */}

      {/* search box */}
      <div className="contain-joblisting">
        <div className="joblistings-searchbar">
          <div className="findJobs-search-box">
            {/* job title search input */}
            <div className="input-1">
              <CiSearch />

              <Autocomplete
                id="free-solo-demo"
                freeSolo
                sx={{ width: "240px", height: "20px" }}
                options={top100Films.map((option) => option.title)}
                renderInput={(params) => (
                  <div ref={params.InputProps.ref}>
                    <input
                      placeholder="Job Title"
                      type="text"
                      {...params.inputProps}
                      style={{ backgroundColor: "transparent" }}
                    />
                  </div>
                )}
              />

              {/* <TextField {...params} sx={{ width: '220px', marginBottom: '10px' }} variant="standard" /> */}
              {/* <input
                type="text"
                placeholder="Job Title"
                onChange={(e) => setJobTitle(e.target.value)}
                // value={searchTermTitle}
              /> */}
            </div>
            {/* job title search input */}

            {/* location search input */}
            <div className="input-1">
              <CiLocationOn />
              <input
                type="text"
                placeholder="Location"
                //onChange={(e) => setJobTitle(e.target.value)}
                // value={searchTermTitle}
              />
            </div>
            {/* location search input */}

            {/* find jobs button */}
            <div
              onClick={() => {}}
              className="findJobs-button"
            >
              <button>
                Find Jobs <FaRegArrowAltCircleRight />
              </button>
            </div>
            {/* find jobs button */}
          </div>
        </div>
      </div>
      {/* search box */}

      <h2
        className="filter-text-head"
        onClick={() => {
          setOpenFilterBox(!openFilterBox);
        }}
      >
        Filters
      </h2>

      {/* filter box small screen */}
      {/* <div className="filter-small-screen">
        {openFilterBox && (
          <Slide top duration={1000}>
            <div>
              <FilterBox />
            </div>
          </Slide>
        )}
      </div> */}
      {/* filter box small screen */}

      <div className="jobListings-searchbox">
        {/* filter box */}
        <div className="filter-box-big-screen">
          <FilterBox jobs={Jobs} />
        </div>
        {/* filter box */}

        {/* job result  */}
        <div className="jobListings-search-filter-results">
          {Jobs?.map((job, index) => {
            return (
              <Link to={`${job.Id}`} key={job.Id}>
                <div className="joblistings-jobitem">
                  <JobItem
                    key={index}
                    jobId={job.Id}
                    title={job.jobTitle}
                    rate={job.rate}
                    jobLoc={job.location}
                    jobDesc={job.des}
                    jobOrg={job.organization}
                    jobImg={job.oranizationIMG}
                    jobOrgloc={job.organizationLocation}
                  />
                </div>
              </Link>
            );
          })}
        </div>
        {/* job result  */}
      </div>
    </>
  );
};

export default JobListings;
