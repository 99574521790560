import React from "react";

const CvIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M4.48683 13.1335C5.0335 12.5468 5.86683 12.5935 6.34683 13.2335L7.02016 14.1335C7.56016 14.8468 8.4335 14.8468 8.9735 14.1335L9.64683 13.2335C10.1268 12.5935 10.9602 12.5468 11.5068 13.1335C12.6935 14.4002 13.6602 13.9802 13.6602 12.2068V4.6935C13.6668 2.00683 13.0402 1.3335 10.5202 1.3335H5.48016C2.96016 1.3335 2.3335 2.00683 2.3335 4.6935V12.2002C2.3335 13.9802 3.30683 14.3935 4.48683 13.1335Z"
        stroke="#1C2533"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.3335 4.6665H10.6668"
        stroke="#1C2533"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 7.3335H10"
        stroke="#1C2533"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CvIcon;
