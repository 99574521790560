import { useCallback } from 'react'

function useSortAlphabetically() {
  return useCallback((props) => {
    const { items, byProperty } = props
    return items.sort((a, b) =>
      a[byProperty] !== b[byProperty]
        ? a[byProperty] < b[byProperty]
          ? -1
          : 1
        : 0
    )
  }, [])
}

export default useSortAlphabetically
