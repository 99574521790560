import React from "react";
import "./ProfileEditDatenCv.css";

const ProfileEditDatenCv = (props) => {
  const { label, icon, placeholder, widthCon, type, value, onChange } = props;
  return (
    <div style={{ width: widthCon }} className="profile-edit-date-cv-body">
      <label
        style={{
          color: "#777",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "24px",
        }}
      >
        {label}
      </label>

      <div style={{ width: widthCon }} className="input-edit-container">
        {icon}
        <input
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          type={type === "date" ? type : "text"}
        />
      </div>
    </div>
  );
};

export default ProfileEditDatenCv;
