import React, { useMemo } from "react";
import "./FilterBox.css";
import FilterInput from "./FilterInput";
import CustomAutoComplete from "../../Pages/dashboard/profile/components/customAutoComplete/CustomAutoComplete";
import useCountries from "../../hook/useCountries";
import toLower from "lodash/fp/toLower";
// import { useState } from 'react'

const FilterBox = () => {
  // const [minRate, setMinRate] = useState("")

  const countries = useCountries();

  const countrySelectedOption = useMemo(() => {
    return countries.find((i) => toLower(i.value) === toLower("NG"));
  }, [countries]);

  const divisions = [
    {
      label: "Locum Pharmacist",
    },
    {
      label: "Locum Nurse",
    },
  ];

  return (
    // isOpen ? (
    <div className="filter-box-container">
      <div className="filters-box-wrapper">
        <h3 className="filter-header">Filters</h3>

        <div className="column">
          <div>
            <h4>Divisions</h4>

            <div className="top">
              <div className="full-auto-complete">
                <CustomAutoComplete
                  isClearable={false}
                  placeholder={"Select division"}
                  options={divisions}
                  customStyles={{
                    control: {
                      width: "100%",
                      borderRadius: "0",
                    },
                  }}
                  // onChange={changeCountryHandler}
                />
              </div>
            </div>
          </div>

          <div>
            <h4>Locations</h4>

            <div className="full-auto-complete">
              <CustomAutoComplete
                isClearable={false}
                placeholder={"select_country"}
                options={countries}
                initOption={countrySelectedOption}
                customStyles={{
                  control: {
                    width: "100%",
                    borderRadius: "4px",
                  },
                }}
                // onChange={changeCountryHandler}
              />
            </div>
          </div>

          <div>
            <h4>Availability</h4>

            <div className="top">
              <FilterInput placeholder="Select Availability" />
            </div>
          </div>

          <div>
            <h4>Pay Rate</h4>
            <div className="jobListings-topSalary">
              <div className="flex-input">
                <div className="full-auto-complete">
                  <CustomAutoComplete
                    isClearable={false}
                    placeholder={"select_country"}
                    options={countries}
                    initOption={countrySelectedOption}
                    customStyles={{
                      control: {
                        width: "100%",
                        borderRadius: "4px",
                      },
                    }}
                    // onChange={changeCountryHandler}
                  />
                </div>
              </div>
              <div className="flex-input">
                <FilterInput
                  placeholder="Min"
                  type="number"
                  endIcon={<p className="icon">₦</p>}
                  // endIcon={<GoCalendar className="icon" />}
                />
                <FilterInput
                  placeholder="Max"
                  type="number"
                  endIcon={<p className="icon">₦</p>}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="reset-filters">
          <button>Reset Filters</button>
        </div>
      </div>
    </div>
    // ) : null
  );
};

export default FilterBox;
