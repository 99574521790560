import React from "react";
import "./NotificationPage.css";

const NotificationPage = () => {
  return (
    <div className="notification-page-container">
      <h6>Notification</h6>
      <div className="notification-page-content">
        {/* box1 */}
        <div className="notification-flex-box">
          <div className="left-notification">
            <h4>Messages</h4>
            <p>
              Notification for new messages. Kindly toggle your choice
              channel(s).
            </p>
          </div>

          <div className="right-notification">
            <div className="toggle-box">
              <label class="toggle-switch">
                <input type="checkbox" />
                <div class="toggle-switch-background">
                  <div class="toggle-switch-handle"></div>
                </div>
              </label>
              <span>Push</span>
            </div>
            <div className="toggle-box">
              <label class="toggle-switch">
                <input type="checkbox" />
                <div class="toggle-switch-background">
                  <div class="toggle-switch-handle"></div>
                </div>
              </label>
              <span>In-app</span>
            </div>
            <div className="toggle-box">
              <label class="toggle-switch">
                <input type="checkbox" />
                <div class="toggle-switch-background">
                  <div class="toggle-switch-handle"></div>
                </div>
              </label>
              <span>Email</span>
            </div>
          </div>
        </div>
        {/* box1 */}

        {/* box2 */}
        <div className="notification-flex-box">
          <div className="left-notification">
            <h4>Jobs</h4>
            <p>
              Job alerts for your selected division. Kindly toggle your choice
              channel(s)
            </p>
          </div>
          <div className="right-notification">
            <div className="toggle-box">
              <label class="toggle-switch">
                <input type="checkbox" />
                <div class="toggle-switch-background">
                  <div class="toggle-switch-handle"></div>
                </div>
              </label>
              <span>Push</span>
            </div>
            <div className="toggle-box">
              <label class="toggle-switch">
                <input type="checkbox" />
                <div class="toggle-switch-background">
                  <div class="toggle-switch-handle"></div>
                </div>
              </label>
              <span>In-app</span>
            </div>
            <div className="toggle-box">
              <label class="toggle-switch">
                <input type="checkbox" />
                <div class="toggle-switch-background">
                  <div class="toggle-switch-handle"></div>
                </div>
              </label>
              <span>Email</span>
            </div>
          </div>
        </div>
        {/* box2 */}
      </div>
    </div>
  );
};

export default NotificationPage;
