import React, { memo } from 'react'
import DatePickerInput from '../../../datePicker/DatePickerInput'
import './TimeFrameInput.css'

const TimeFrameInput = ({label}) => {
  return (
    <div className='timeframe-bg'>
        <label>{label}</label>
        <div className='timeframe-input-bg'>
            <DatePickerInput type='date' width='60%' />
            <DatePickerInput type='time' width='40%' />
        </div>
    </div>
  )
}

export default memo(TimeFrameInput);