import { config } from "../../config";
import axios from "axios";

const signupHeader = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

export const getUser = async (token, acc_type) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const res = await axios.get(
    `${config.testApiBaseUrl}/${acc_type}/view-${acc_type}`
  );
  return { data: res.data, status: res.status };
};

export const getSoD = async (token, acc_type, type) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const res = await axios.get(
    `${config.testApiBaseUrl}/${acc_type}/view-${type}`
  );
  return { data: res.data, status: res.status };
};

export const updateUserDetails = async (values, token, acc_type) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const res = await axios.post(
    `${config.testApiBaseUrl}/${acc_type}/update-profile`,
    values,
    signupHeader
  );
  return { data: res.data, status: res.status };
};
