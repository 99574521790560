import React from "react";

const SendIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M1.94681 9.31504C1.42481 9.14104 1.41981 8.86004 1.95681 8.68104L21.0438 2.31904C21.5728 2.14304 21.8758 2.43904 21.7278 2.95704L16.2738 22.043C16.1238 22.572 15.8188 22.59 15.5948 22.088L12.0008 14L18.0008 6.00004L10.0008 12L1.94681 9.31504Z"
        fill="#5372EA"
      />
    </svg>
  );
};

export default SendIcon;
