import React, { useState, useEffect } from "react";
import JobItem from "../jobItem/JobItem";
import { jobsArray } from "../findJobs/Jobs";
import { Link } from "react-router-dom";
import { CiSearch, CiLocationOn } from "react-icons/ci";
import { FaRegArrowAltCircleRight } from "react-icons/fa";

const PhysiciansItem = () => {
  const [visible, setVisible] = useState(3);
  const [PhysicianJobsData, setPhysicianJobsData] = useState(jobsArray);
  const [searchLocationValue, setSearchLocationValue] = useState("");
  const [filteredPhysicians, setFilteredPhysicians] = useState();

  const loadMore = () => {
    setVisible(visible + 3);
  };

  useEffect(() => {
    const filtPhysicians = jobsArray.filter(
      (job) => job.jobType === "physicians"
    );
    setPhysicianJobsData(filtPhysicians);
    setFilteredPhysicians(filtPhysicians);
  }, []);

  const searchLocationHandler = () => {
    const searchedLocation = filteredPhysicians.filter((job) => {
      return job.location
        .toLowerCase()
        .includes(searchLocationValue.toLowerCase());
    });
    setPhysicianJobsData(searchedLocation);

    if (PhysicianJobsData.length === 0) {
      const searchedLocation = filteredPhysicians.filter((job) => {
        return job.location
          .toLowerCase()
          .includes(searchLocationValue.toLowerCase());
      });
      setPhysicianJobsData(searchedLocation);
    }
  };

  return (
    <div>
      {/* search box */}
      <div className="findJobs-search-box">
        <div className="input-1">
          <CiSearch />
          <input type="text" placeholder="physicians" value="Physicians" />
        </div>
        <div className="input-1">
          <CiLocationOn />
          <input
            type="text"
            placeholder="Location"
            onChange={(e) => {
              setSearchLocationValue(e.target.value);
            }}
          />
        </div>
        <div className="findJobs-button">
          <button onClick={searchLocationHandler}>
            Find Jobs <FaRegArrowAltCircleRight />
          </button>
        </div>
      </div>
      {/* search box */}

      <div className="proxy-job-container">
        {PhysicianJobsData.length === 0 ? (
          <h2 className="no-jobs-found"> No Jobs Found! </h2>
        ) : (
          <div className="job-result-container">
            {PhysicianJobsData?.slice(0, visible).map((job) => {
              return (
                <Link to={`/joblistings/${job.Id}`} key={job.Id}>
                  <div className="findJobs-jobitem">
                    <JobItem
                      jobId={job.Id}
                      title={job.jobTitle}
                      rate={job.rate}
                      jobLoc={job.location}
                      jobDesc={job.des}
                      jobOrg={job.organization}
                      jobImg={job.oranizationIMG}
                      jobOrgloc={job.organizationLocation}
                    />
                  </div>
                </Link>
              );
            })}
          </div>
        )}
      </div>

      {visible < PhysicianJobsData.length && (
        <div className="button-full">
          <button className="job-view-more" onClick={loadMore}>
            View More
          </button>
        </div>
      )}
    </div>
  );
};

export default PhysiciansItem;
