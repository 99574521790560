import React from "react";
import { Route, Routes } from "react-router-dom";
import "aos/dist/aos.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Home from "../../Pages/home/Home";
import About from "../../Pages/about/About";
import Contact from "../../Pages/contact/Contact";
import JobListings from "../../Pages/jobListings/JobListings";
import JobDetails from "../../Pages/jobDetails/JobDetails";
import Login from "../../Pages/account/login/Login";
import NotFound from "../../Pages/notFound/NotFound";
import RegularDashboard from "../../navigations/RegularDashboard/RegularDashboard";
import Dashboard from "../../Pages/dashboard/Dashboard";
import Shortlist from "../../Pages/dashboard/shortlist/Shortlist";
import JobActivity from "../../Pages/dashboard/jobActivity/JobActivity";
import Settings from "../../Pages/dashboard/settings/Settings";
import ProfileEdit from "../../Pages/dashboard/profile/ProfileEdit ";
import Messages from "../../Pages/dashboard/messages/Messages";
import Payment from "../../Pages/dashboard/payment/Payment";
import PostJob from "../../Pages/dashboard/postJob/PostJob";
import AdminDashboard from "../../Pages/adminDashboard/AdminDashboard";
import PostedJobs from "../../Pages/adminDashboard/postedJobs/PostedJobs";
import Candidates from "../../Pages/adminDashboard/candidates/Candidates";
import {
  regularDashboard,
} from "../dashboardDrawer/NavigationData";
import Register from "../../Pages/account/register/Register";
import useAuth from "../../hook/useAuth";
import ForgetPassword from "../../Pages/account/forgetPassword/ForgetPassword";

AOS.init({
  disable: false,
  startEvent: "DOMContentLoaded",
  initClassName: "aos-init",
  animatedClassName: "aos-animate",
  useClassNames: false,
  disableMutationObserver: false,
  debounceDelay: 50,
  throttleDelay: 99,
  offset: 120,
  delay: 0,
  duration: 1000,
  easing: "ease",
  once: false,
  mirror: false,
  anchorPlacement: "top-bottom",
});

const MainRouter = () => {
  useAuth();

  return (
    <Routes>
      <Route index path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/joblistings" element={<JobListings />} />
      <Route path="/joblistings/:id/:posted?" element={<JobDetails />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgetpassword" element={<ForgetPassword />} />
      <Route path="/register" element={<Register />} />
      <Route path="*" element={<NotFound />} />

      <Route
        path="/"
        element={<RegularDashboard navItems={regularDashboard} />}
      >
        <Route index path="dashboard" element={<Dashboard />} />
        <Route path="shortlist" element={<Shortlist />} />
        <Route path="activity" element={<JobActivity />} />
        <Route path="/">
          <Route index path="settings" element={<Settings />} />
          <Route path="/settings/profileedit/:type?" element={<ProfileEdit />} />
        </Route>
        <Route path="messages" element={<Messages />} />
        <Route path="payment" element={<Payment />} />
        <Route path="postjob" element={<PostJob />} />
        <Route index path="locummanager" element={<AdminDashboard />} />
        <Route path="postedJobs" element={<PostedJobs />} />
        <Route path="applicants" element={<Candidates />} />
      </Route>
    </Routes>
  );
};

export default MainRouter;
