import React, { useState } from "react";
import "./Messages.css";
import pics from "../../../assets/message-pics.png";
import ConversationBroad from "./components/conversationBroad/ConversationBroad";
import SendIcon from "../../../components/icon/SendIcon";
import EachChat from "./components/eachChat/EachChat";

const Messages = () => {
  const firstMsg = "Want to find out about my listings?";
  const secMsg = "Do you need an agent?";
  const thirdMsg = "Call me on (555)-0890";

  const [showChat, setShowChat] = useState(false);

  return (
    <div className="messages-container">
      <h2>Messages👋🏾</h2>

      <div className="message-app-content">
        <div className={`conversation-list ${showChat ? 'hide-cover' : 'show-cover'}`}>
          <div className="conversation-list-text">
            <h3>Conversations</h3>
          </div>

          <div className="round-up">
            <ConversationBroad
              type="UNREAD"
              handleShowChat={() => setShowChat(true)}
            />
            <div className="divider" />
            <ConversationBroad
              type="OLDER"
              handleShowChat={() => setShowChat(true)}
            />
          </div>
        </div>
        <div className={`each-conversation ${showChat ? 'show-cover' : 'hide-cover'}`}>
          <div className="each-conversation-header">
            <div className="profile">
              <img src={pics} alt="pics" />
              <div className="profile-text">
                <h6>Eleanor Pena</h6>
                <div className="profile-status">
                  <div
                    style={{ backgroundColor: "#00C48C" }}
                    className="active-status"
                  />
                  <p>Online</p>
                </div>
              </div>
            </div>

            <div className="notification-switch">
              <div className="toggle-box">
                <span>Receive E-mail Notifications</span>
                <label class="toggle-switch">
                  <input type="checkbox" />
                  <div class="toggle-switch-background">
                    <div class="toggle-switch-handle"></div>
                  </div>
                </label>
              </div>
            </div>
          </div>

          <div className="each-conversation-content">
            <div className="main-chat-broad">
              <EachChat text="Hey man, i wanna find out about your listings and stuff... have you got a 3-bed?" />
              <EachChat text="Hey, Eleanor" owner />
            </div>
            <div className="message-text-bg">
              <div className="alternate-text-bg">
                <button>{firstMsg}</button>
                <button>{secMsg}</button>
                <button>{thirdMsg}</button>
              </div>
              <div className="text-field-bg">
                <input className="text-field" />
                <div className="btns-input">
                  <button className="send-btn-input">
                    <SendIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Messages;
