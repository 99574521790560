import { useFormik } from "formik";
import * as Yup from "yup";
import { signIn } from "../endpoints/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useActions } from "./useActions";
import {
  errorProps,
  getLocalStorageWithExpiration,
  setLocalStorageWithExpiration,
  successProps,
} from "../utils";
import { getUser } from "../endpoints/user";

const key = "user-values";
const expirationHours = 24;

const useLogin = () => {
  const navigate = useNavigate();
  const actions = useActions();

  const getUserDetails = async (values) => {
    const { token, acc_type } = values;

    try {
      const { data, status } = await getUser(token, acc_type);
      if (status === 201 || status === 200 || data.status === true) {
        actions.setUser({ ...data.data });
        navigate("/dashboard");
      }
    } catch (error) {
      if (error.message === "Network Error") {
        toast.error(error.message, errorProps);
      } else {
        toast.error("Can not get user", errorProps);
      }
    }
  };

  const handleSubmit = async (values) => {
    try {
      const { data, status } = await signIn(values);
      if (status === 200 && data.status === true) {
        if (!data?.account_data?.role == 1) {
          toast.success("Successfully Login", successProps);
          const values = {
            token: data?.token,
            acc_type: data?.account_data?.account_type,
          };
          setLocalStorageWithExpiration(key, values, expirationHours);
          const keyValues = getLocalStorageWithExpiration(key);
          if (keyValues) getUserDetails(keyValues);
        } else {
          toast.error(
            "Log in as an individual/organisation and not an admin",
            errorProps
          );
        }
      }
    } catch (error) {
      if (error.message === "Network Error") {
        toast.error(error.message, errorProps);
      } else if (error.response.status === 401) {
        toast.error("Wrong credentials", errorProps);
      } else {
        toast.error("Login failed", errorProps);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Provide vaild email address")
        .required("Required"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
          "Password must contain at least one lowercase letter, one uppercase letter, and one number"
        )
        .required("Required"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return formik;
};

export default useLogin;
