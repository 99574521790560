import React, { useMemo } from "react";
import "./PostJob.css";
import ProfileEditInput from "../profile/components/profileEditInput/ProfileEditInput";
import CustomPhoneInput from "../profile/components/customPhoneInput/CustomPhoneInput";
import CustomAutoComplete from "../profile/components/customAutoComplete/CustomAutoComplete";
import useCountries from "../../../hook/useCountries";
import toLower from "lodash/fp/toLower";
import ProfileEditDatenCv from "../profile/components/profileEditDatenCv/ProfileEditDatenCv";
import PostJobBtn from "./postJobBtn/PostJobBtn";

const PostJob = () => {
  const countries = useCountries();
  const countrySelectedOption = useMemo(() => {
    return countries.find((i) => toLower(i.value) === toLower("NG"));
  }, [countries]);

  const requireItems = [
    "NYSC complete",
    "Fresh Graduate",
    "Pre-internship",
    "Pre-internship",
    "Post Internship",
    "Post Internship",
    "2 years of experience",
    "2 years of experience",
  ];

  const jobsTitle = [
    {
      label: "Locum Pharmacist",
    },
    {
      label: "Locum Nurse",
    },
  ];

  const divions = [
    {
      label: "Pharmacist",
    },
    {
      label: "Nurse",
    },
  ];

  return (
    <div className="postjob-container">
      <h4>Post a Job</h4>

      <div className="postjob-content">
        <div className="inner-box">
          <div className="first-box">
            <h5>About Company</h5>
            <p>Kindly provide your company's name and other details.</p>
          </div>

          <div className="second-box">
            <div className="inner-input-1">
              <div className="auto-complete-con">
                <ProfileEditInput
                  label="Company Name"
                  placeholder="Please enter your company name"
                  width={"100%"}
                  widthCon={"100%"}
                />
              </div>
              <div className="auto-complete-con">
                <ProfileEditInput
                  label="Contact email address"
                  placeholder="Enter your email address"
                  width={"100%"}
                  widthCon={"100%"}
                />
              </div>
            </div>
            <div className="inner-input-1">
              <div className="auto-complete-con">
                <CustomPhoneInput
                  label="Phone Number"
                  width={"100%"}
                  widthCon={"100%"}
                />
              </div>

              <div className="auto-complete-con">
                <CustomAutoComplete
                  isClearable={false}
                  label="Nationality"
                  placeholder={"select_country"}
                  options={countries}
                  initOption={countrySelectedOption}
                  // onChange={changeCountryHandler}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="inner-box">
          <div className="first-box">
            <h5>About Job</h5>
            <p>
              Just a little secret; jobs with better offer get more engagement.
              Don't forget to make your offer enticing 😃{" "}
            </p>
          </div>
          <div className="second-box">
            <div className="inner-input-1">
              <div className="auto-complete-con">
                <CustomAutoComplete
                  isClearable={false}
                  label="Job Title"
                  placeholder={"Select job title"}
                  options={jobsTitle}

                  // onChange={changeCountryHandler}
                />
              </div>
              <div className="auto-complete-con">
                <ProfileEditDatenCv
                  label="Start Date"
                  placeholder="DD-MM-YYY"
                  widthCon={"100%"}
                  icon={
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="vuesax/linear/calendar">
                        <g id="calendar">
                          <path
                            id="Vector"
                            d="M8 2V5"
                            stroke="#292D32"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            id="Vector_2"
                            d="M16 2V5"
                            stroke="#292D32"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            id="Vector_3"
                            d="M3.5 9.09H20.5"
                            stroke="#292D32"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            id="Vector_4"
                            d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"
                            stroke="#292D32"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            id="Vector_5"
                            d="M15.6947 13.7H15.7037"
                            stroke="#292D32"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            id="Vector_6"
                            d="M15.6947 16.7H15.7037"
                            stroke="#292D32"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            id="Vector_7"
                            d="M11.9955 13.7H12.0045"
                            stroke="#292D32"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            id="Vector_8"
                            d="M11.9955 16.7H12.0045"
                            stroke="#292D32"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            id="Vector_9"
                            d="M8.29431 13.7H8.30329"
                            stroke="#292D32"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            id="Vector_10"
                            d="M8.29431 16.7H8.30329"
                            stroke="#292D32"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                      </g>
                    </svg>
                  }
                />
              </div>
            </div>
            <div className="inner-input-1">
              <div className="auto-complete-con">
                <CustomAutoComplete
                  isClearable={false}
                  label="Location"
                  placeholder={"select_country"}
                  options={countries}
                  initOption={countrySelectedOption}
                  // onChange={changeCountryHandler}
                />
              </div>
              <div className="auto-complete-con">
                <CustomAutoComplete
                  isClearable={false}
                  label="Division"
                  placeholder="Select division"
                  options={divions}

                  // onChange={changeCountryHandler}
                />
              </div>
            </div>
            <div className="inner-input-1">
              <div className="auto-complete-con">
                <ProfileEditInput
                  label="Hourly Rate"
                  placeholder="N0.00"
                  width={"100%"}
                  widthCon={"100%"}
                />
              </div>
              <div className="auto-complete-con">
                <ProfileEditInput
                  label="Contract Length"
                  placeholder="3 months"
                  width={"100%"}
                  widthCon={"100%"}
                />
              </div>
            </div>
            <div className="inner-input-1">
              <div className="auto-complete-con">
                <ProfileEditDatenCv
                  label="End Date"
                  placeholder="DD-MM-YYY"
                  widthCon={"100%"}
                  icon={
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="vuesax/linear/calendar">
                        <g id="calendar">
                          <path
                            id="Vector"
                            d="M8 2V5"
                            stroke="#292D32"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            id="Vector_2"
                            d="M16 2V5"
                            stroke="#292D32"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            id="Vector_3"
                            d="M3.5 9.09H20.5"
                            stroke="#292D32"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            id="Vector_4"
                            d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"
                            stroke="#292D32"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            id="Vector_5"
                            d="M15.6947 13.7H15.7037"
                            stroke="#292D32"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            id="Vector_6"
                            d="M15.6947 16.7H15.7037"
                            stroke="#292D32"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            id="Vector_7"
                            d="M11.9955 13.7H12.0045"
                            stroke="#292D32"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            id="Vector_8"
                            d="M11.9955 16.7H12.0045"
                            stroke="#292D32"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            id="Vector_9"
                            d="M8.29431 13.7H8.30329"
                            stroke="#292D32"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            id="Vector_10"
                            d="M8.29431 16.7H8.30329"
                            stroke="#292D32"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                      </g>
                    </svg>
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className="inner-box">
          <div className="first-box">
            <h5>Responsibilities</h5>
            <p>
              Outline clear responsibilities to get the best off your
              placeholder.{" "}
            </p>
          </div>

          <div className="second-box">
            <label className="desc-text">Describe the responsibilities</label>
            <textarea cols="60" />
          </div>
        </div>

        <div className="inner-box">
          <div className="first-box">
            <h5>Requirements</h5>
            <p>State your Requirements for applicants. </p>
          </div>

          <div className="second-box">
            <label className="desc-text">Requirements</label>
            <textarea cols="60" />
          </div>
        </div>

        <div className="btn-bg">
          <PostJobBtn
            label="Delete"
            customStyle={{
              background: "#F9E3E0",
              color: "#DB3E4D",
            }}
          />
          <PostJobBtn
            label="Save to Draft"
            customStyle={{
              background: "#E5F3FF",
              color: "#245293",
            }}
          />
          <PostJobBtn
            label="Publish"
            customStyle={{
              background: "#245293",
              color: "#fff",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PostJob;
