import React, { useState, useEffect } from 'react'
import JobItem from '../jobItem/JobItem'
import { jobsArray } from '../findJobs/Jobs'
import { Link } from 'react-router-dom'
import { CiSearch, CiLocationOn } from "react-icons/ci"
import { FaRegArrowAltCircleRight } from "react-icons/fa"


const PhysiciansItem = () => {
    const [visible, setVisible] = useState(3)
    const [PhamarcistsJobsData, setPhamarcistsJobsData] = useState([])
    const [searchLocationValue, setSearchLocationValue] = useState("")
    const [filteredPharmacists, setFilteredPhamarcists] = useState()

    const loadMore = () => {
        setVisible(visible + 3)
    }

    useEffect(() => {
        const filtPhamarcists = jobsArray.filter(job => job.jobType === "pharmacists")
        setPhamarcistsJobsData(filtPhamarcists)
        setFilteredPhamarcists(filtPhamarcists)
    }, [])

    const searchLocationHandler = () => {
        const searchedLocation = filteredPharmacists.filter((job) => {
            return job.location.toLowerCase().includes(searchLocationValue.toLowerCase())
        })
        setPhamarcistsJobsData(searchedLocation)

        if (PhamarcistsJobsData.length === 0) {
            const searchedLocation = filteredPharmacists.filter((job) => {
                return job.location.toLowerCase().includes(searchLocationValue.toLowerCase())
            })
            setPhamarcistsJobsData(searchedLocation)
        }
    }

    return (
        <div>

            {/* search box */}
            <div className="findJobs-search-box">
                <div className="input-1">
                    <CiSearch />
                    <input type="text" placeholder='pharmacists' value="Pharmacists" />
                </div>
                <div className="input-1">
                    <CiLocationOn />
                    <input type="text" placeholder='Location' onChange={(e) => { setSearchLocationValue(e.target.value) }} />
                </div>
                <div className='findJobs-button'>
                    <button onClick={searchLocationHandler}>Find Jobs <FaRegArrowAltCircleRight /></button>
                </div>
            </div>
            {/* search box */}


            <div className="proxy-job-container">
                {
                    PhamarcistsJobsData.length === 0 ? (
                        <h2 className='no-jobs-found'>  No Jobs Found! </h2>)
                        :
                        (
                            <div className="job-result-container">
                                {
                                    PhamarcistsJobsData?.slice(0, visible).map((job) => {
                                        return (
                                            <Link to={`/joblistings/${job.Id}`} key={job.Id}>
                                                <div className='findJobs-jobitem'>
                                                    <JobItem
                                                        jobId={job.Id}
                                                        title={job.jobTitle}
                                                        rate={job.rate}
                                                        jobLoc={job.location}
                                                        jobDesc={job.des}
                                                        jobOrg={job.organization}
                                                        jobImg={job.oranizationIMG}
                                                        jobOrgloc={job.organizationLocation}
                                                    />
                                                </div>
                                            </Link>
                                        )
                                    })}
                            </div>
                        )
                }
            </div>

            {visible < PhamarcistsJobsData.length && (
                <div className="button-full">
                    <button className='job-view-more' onClick={loadMore}>View More</button>
                </div>
            )}
        </div>
    )
}

export default PhysiciansItem