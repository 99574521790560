import React, { useEffect, useState } from "react";
import { errorProps, getLocalStorageWithExpiration } from "../utils";
import { toast } from "react-toastify";
import { getSoD } from "../endpoints/user";

const useSkills = () => {
  const key = "user-values";
  const [loading, setLoading] = useState(false);
  const [skillsList, setSkillsList] = useState([]);

  const [keyValues] = useState(getLocalStorageWithExpiration(key));

  const fetchSkills = async (values) => {
    const { token, acc_type } = values;
    const type = "skills";
    setLoading(true);
    try {
      const { data, status } = await getSoD(token, acc_type, type);
      if (status === 201 || status === 200 || data.status === true) {
        const newSkillLists = data?.data?.allSkill?.map((i) => {
          return { label: i.name };
        });
        setSkillsList(newSkillLists)
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setSkillsList([]);
      if (error.message === "Network Error") {
        toast.error(error.message, errorProps);
      } else {
        toast.error("Can not get skill sets", errorProps);
      }
    }
  };

  useEffect(() => {
    if (keyValues) fetchSkills(keyValues);
  }, []);

  return { loading, skillsList };
};

export default useSkills;
